import { Grid } from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { Input } from "../../../../components/Input";
import { Select } from "../../../../components/Select";
import { useAuth } from "../../../../hooks/auth";
import SelectOption from "../../../../interfaces/SelectOption";

interface Props {
  filter: any;
  onFilter: any;
}

const Filter = ({ filter, onFilter }: Props) => {
  const {
    authData: { user },
  } = useAuth();
  const userIsAdminOrManager =
    user.perfil === "admin" || user.perfil === "gerente";

  // const 3 = userIsAdminOrManager ? 2 : 3;

  const statusOptions: SelectOption[] = [
    { text: "Emitido ou Pendente", value: "EP" },
    { text: "Emitido", value: "E" },
    { text: "Pendente", value: "P" },
    { text: "Revogado ou Cancelado", value: "RC" },
    { text: "Revogado", value: "R" },
    { text: "Cancelado", value: "C" },
  ];

  const situationOptions: SelectOption[] = [
    { text: "Ativo", value: "A" },
    { text: "Vencido", value: "V" },
  ];

  const productTypeOptions: SelectOption[] = [
    { text: "Pessoa Jurídica", value: "PJ" },
    { text: "Pessoa Física", value: "PF" },
  ];

  const dtOptions: SelectOption[] = [
    { text: "Início da Validade", value: "dtInicioValidade" },
    { text: "Fim da Validade", value: "dtFimValidade" },
    { text: "Revogação", value: "dtRevogacao" },
    { text: "Atendimento", value: "dtAvp" },
  ];

  return (
    <div className="flex-grow-1">
      <Grid container spacing={2}>
        <Grid xs={12} md item>
          <Input
            placeholder="Protocolo"
            value={filter.protocolo}
            onChange={onFilter("protocolo")}
          />
        </Grid>
        <Grid xs={12} md item>
          <Input
            placeholder="Documento"
            value={filter.documento}
            onChange={onFilter("documento")}
            mask={
              filter.documento &&
              filter.documento.replace(/[^0-9]/g, "").length > 11
                ? "99.999.999/9999-99"
                : "999.999.999-999"
            }
          />
        </Grid>
        <Grid xs={12} md item>
          <Input
            placeholder="Titular CPF"
            value={filter.titularCpf}
            mask="999.999.999-99"
            onChange={onFilter("titularCpf")}
          />
        </Grid>
        {user.perfil === "admin" && (
          <Grid xs={12} md item>
            <Input
              placeholder="AVP CPF"
              value={filter.avpCpf}
              mask="999.999.999-99"
              onChange={onFilter("avpCpf")}
            />
          </Grid>
        )}
        {userIsAdminOrManager && (
          <Grid xs={12} md item>
            <Select
              label="Tipo do Produto"
              options={productTypeOptions}
              value={filter.produto}
              onChange={onFilter("produto")}
              name="produto"
            />
          </Grid>
        )}
      </Grid>

      {userIsAdminOrManager && (
        <Grid className="mt8" container spacing={2}>
          <Grid xs={12} md item>
            <Select
              label="Status"
              options={statusOptions}
              value={filter.status}
              onChange={onFilter("status")}
              name="status"
            />
          </Grid>
          <Grid xs={12} md item>
            <Select
              label="Situaçao"
              options={situationOptions}
              value={filter.situacao}
              onChange={onFilter("situacao")}
              name="situacao"
            />
          </Grid>
          <Grid xs={12} md item>
            <Select
              label="Data"
              options={dtOptions}
              value={filter.dt}
              onChange={onFilter("dt")}
              name="dt"
            />
          </Grid>
          <Grid xs={12} md item>
            <KeyboardDatePicker
              autoOk
              clearable
              inputVariant="outlined"
              label="Início Período"
              format="DD/MM/yyyy"
              onChange={onFilter("dtInicio")}
              invalidDateMessage={"Data inválida"}
              value={filter.dtInicio ?? null}
              inputProps={{ style: { height: 56 } }}
              fullWidth
            />
          </Grid>
          <Grid xs={12} md item>
            <KeyboardDatePicker
              autoOk
              clearable
              inputVariant="outlined"
              label="Fim Período"
              format="DD/MM/yyyy"
              onChange={onFilter("dtFim")}
              invalidDateMessage={"Data inválida"}
              value={filter.dtFim ?? null}
              inputProps={{ style: { height: 56 } }}
              fullWidth
            />
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default Filter;
