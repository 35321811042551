import React from "react";
import { Card } from "@material-ui/core";
import { useAuth } from "../../hooks/auth";

export const Home = () => {
  const {
    authData: { user },
  } = useAuth();

  return (
    <div className="pa8 pa24-l h100">
      <Card className="pa16 h100">
        <h2>Olá, {user.nome}!</h2>
        <br />
        <span className="pt16">
          Utilize o menu lateral paga navegar entre as páginas que possui
          acesso!
        </span>
      </Card>
    </div>
  );
};
