import { useEffect, useRef, useState } from "react";
import _get from "lodash/get";

interface Props {
  filter: any;
  FilterComponent: any;
  setFilter: (args: any) => void;
  onFilter: () => void;
  fetchOptions?: any;
  sideEffects?: any;
}

const Filter = ({
  filter,
  setFilter,
  FilterComponent,
  fetchOptions = {},
  sideEffects,
  onFilter,
  ...props
}: Props) => {
  const [selectOptions, setSelectOptions] = useState({});

  const debounce: any = useRef(null);

  useEffect(() => {
    if (debounce.current) {
      clearTimeout(debounce.current);
    }
    debounce.current =
      Object.entries(filter).length === 0
        ? onFilter()
        : setTimeout(() => {
            onFilter();
          }, 1000);
  }, [filter]);

  const handleFilter = (name: any) => (ev: any) => {
    const value = ev?.target?.value ?? ev;

    setFilter((prevState: any) => ({
      ...prevState,
      [name]: value !== "" ? value : undefined,
    }));
  };

  const handleSideEffect = (name: any) => async (ev: any) => {
    const effect = _get(sideEffects, name, undefined);
    if (effect) {
      const { fetch, transform, toField } = effect;
      const { data } = await fetch(ev);
      const selectData = transform(data);
      setSelectOptions((prevState) => ({
        ...prevState,
        [toField]: selectData,
      }));
    }
  };

  return (
    <FilterComponent
      filter={filter}
      onFilter={handleFilter}
      selectOptions={selectOptions}
      onSideEffect={handleSideEffect}
      {...props}
    />
  );
};

export default Filter;
