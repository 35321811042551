import { Card, Typography } from "@material-ui/core";
import moment, { Moment } from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import Button from "../../components/Button";
import EmissionTable from "../../components/EmissionTable";
import Filter from "../../components/Filter";
import { useAuth } from "../../hooks/auth";
import EmissionGrid from "../../interfaces/EmissionGrid";
import FilterEmission from "../../interfaces/FilterEmission";
import Emissao from "../../resources/emissao";
import addToast from "../../utils/toast";
import validateEmissionFilter from "../../utils/validateEmissionFilter";
import FilterComponent from "./components/Filter";

interface FilterState {
  [key: string]: string;
}

const Emissoes = () => {
  const [emissoes, setEmissoes] = useState([] as EmissionGrid[]);
  const [filter, setFilter] = useState<FilterState>({} as FilterState);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(5);
  const [total, setTotal] = useState(0);
  const [searchDate, setSearchDate] = useState<Moment>(moment());
  const {
    authData: { user },
  } = useAuth();
  // const [validationOk, setValidationOk] = useState(true);

  const canCleanFilters = useMemo(
    () => Object.keys(filter).some((key) => Boolean(filter[key])),
    [filter]
  );

  const getEmissions = useCallback(
    (userFilter: FilterEmission, page: number, perPage: number) => {
      const filteredSomething = Object.keys(filter).some((key) =>
        Boolean(filter[key])
      );

      if (filteredSomething) {
        try {
          if (validateEmissionFilter(userFilter)) {
            Emissao.getAll(userFilter, page + 1, perPage)
              .then((response) => {
                const { data } = response;
                const { emissoes } = data;

                if (emissoes.length === 0) {
                  if (page > 0) {
                    setPage(0);
                  }
                  if (canCleanFilters) {
                    addToast("Nenhum resultado encontrado!", { type: "error" });
                  }
                }

                setEmissoes(emissoes);
                setTotal(data.total);
                setSearchDate(moment());
              })
              .catch((error) => {
                const { data } = error;
                if (data) {
                  addToast(data.message, { type: "error" });
                }
              });
          }
        } catch (error: any) {
          addToast(error.message, { type: "error" });
        }
      } else {
        setEmissoes([]);
        setPage(0);
      }
    },
    [filter]
  );

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handlePerPageChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClearButtonClick = (event: any) => {
    setFilter({});
    setEmissoes([]);
  };

  useEffect(() => {
    getEmissions(filter, page, perPage);
  }, [page, perPage]);

  return (
    <div className="pa8 pa24-l">
      <Card className="pa16">
        <div className="flex justify-between mb8">
          <div
            className="flex justify-center items-center"
            style={{ height: 36 }}
          >
            <h2>Emissões</h2>
          </div>
          <div className="flex">
            {canCleanFilters && (
              <Button
                buttonValue="Limpar Filtros"
                onClick={handleClearButtonClick}
                variant="text"
              />
            )}
          </div>
        </div>

        <Filter
          filter={filter}
          setFilter={setFilter}
          FilterComponent={FilterComponent}
          onFilter={() => {
            getEmissions(filter, page, perPage);
          }}
        />
      </Card>
      {canCleanFilters && emissoes.length > 0 && (
        <div className="pa20 mt24">
          <Typography variant="body2">
            Consulta Realizada em{" "}
            <b> {searchDate.format("DD/MM/YYYY HH:mm:ss")}</b>
          </Typography>

          <EmissionTable
            page={page}
            perPage={perPage}
            total={total}
            data={emissoes}
            userProfile={user.perfil}
            handlePageChange={handlePageChange}
            handlePerPageChange={handlePerPageChange}
          />
        </div>
      )}
    </div>
  );
};

export default Emissoes;
