import { useEffect, useState } from "react";
import * as yup from "yup";
import cx from "classnames";
import { Formik } from "formik";
import { Switch } from "@material-ui/core";
import { Link, RouteComponentProps } from "react-router-dom";

import styles from "./style.module.css";
import { useAuth } from "../../hooks/auth";
import Input from "../../components/Input";
import Button from "../../components/Button";
import logo from "../../assets/img/main-logo.png";

interface FormData {
  email: string;
  password: string;
}

const Login = ({ history }: RouteComponentProps) => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const { signIn, signOut } = useAuth();
  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email("Email inválido!")
      .required("Email é um campo obrigatório!"),
    password: yup.string().required("Senha é um campo obrigatório!"),
  });

  const onSubmit = (formValues: FormData) => {
    signIn(formValues, history);
  };

  useEffect(() => {
    signOut(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div className="flex w-100 vh-100">
        <div
          className={cx(
            "dn w-60 flex-m  flex-column justify-center items-end",
            styles.bgLogo
          )}
        >
          <p className="f96"> Autoridade</p>
          <p className="f48">Certificadora</p>
        </div>
        <div className="flex w-100 w-40-m flex-column justify-center items-center">
          <div className="bg-zircon">
            <div className="flex justify-center items-center bg-zircon h4 mb12">
              <img src={logo} alt="Logo BrasilCertec" />
            </div>
            <Formik
              initialValues={{
                email: "",
                password: "",
              }}
              enableReinitialize
              validationSchema={validationSchema}
              onSubmit={onSubmit}
              validateOnBlur={false}
              validateOnChange={false}
            >
              {({ values, errors, handleSubmit, handleChange, handleBlur }) => (
                <form
                  className="w-100 pa12 ma4 br3"
                  style={{ maxWidth: "25rem" }}
                  onSubmit={handleSubmit}
                >
                  <div className="mb16 w-100">
                    <Input
                      name="email"
                      type="email"
                      placeholder="E-mail"
                      label="E-mail"
                      mask={null}
                      error={errors.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      variant="outlined"
                    />
                  </div>
                  <div className="w-100">
                    <Input
                      name="password"
                      type={passwordVisible ? "text" : "password"}
                      placeholder="Senha"
                      label="Senha"
                      mask={null}
                      error={errors.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                      variant="outlined"
                    />
                  </div>
                  <div className="mb12 f14 flex justify-end items-center">
                    Mostrar senha
                    <Switch
                      checked={passwordVisible}
                      color="primary"
                      onClick={() =>
                        setPasswordVisible((oldValue) => !oldValue)
                      }
                    />
                  </div>
                  <div className="flex justify-between items-center">
                    <Link
                      to="/change-password"
                      className="f14 mt4 pointer underline-hover link black-90"
                    >
                      Esqueceu a senha?
                    </Link>
                    <Button type="submit" buttonValue="LOG IN" />
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
