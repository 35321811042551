import { Paper, Table, TableContainer } from "@material-ui/core";

import TBody from "./components/TBody";
import THead from "./components/THead";
import TFooter from "./components/TFooter";
import { ICustomTableProps } from "./types";

const CustomTable = <T extends object>({
  data,
  headers,
  cells,
  rowKeyProperty,
  orderDirection,
  orderBy,
  numberOfLoadingRows,
  handleSort,
  onRowClick,
  customElements,
  actions,
  pagination,
}: ICustomTableProps<T>) => (
  <>
    <TableContainer component={Paper}>
      <Table>
        <THead
          headers={headers}
          orderDirection={orderDirection}
          orderBy={orderBy}
          handleSort={handleSort}
          actions={actions}
        />
        <TBody
          data={data}
          cells={cells}
          rowKeyProperty={rowKeyProperty}
          numberOfLoadingRows={numberOfLoadingRows}
          onRowClick={onRowClick}
          customElements={customElements}
          actions={actions}
        />
      </Table>
    </TableContainer>
    {data && pagination && (
      <TFooter
        page={pagination.page}
        count={pagination.count}
        onChange={pagination.onChange}
      />
    )}
  </>
);

export default CustomTable;
