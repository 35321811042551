import FilterUsers from "../interfaces/FilterUsers";
import api from "./api";

const baseUrl = "/usuario";

const Usuario = {
  getAll: (filter: FilterUsers) => {
    return api.get(baseUrl, {
      params: filter,
    });
  },
  update: (user: any) => {
    return api.put(baseUrl, user);
  },
};

export default Usuario;
