import api from './api';

const baseUrl = '/public';

const PublicEndpoints = {
  getProtocolStatus: (protocol: number, token: string) => {
    return api.post(`${baseUrl}/emission-status/${protocol}`, {
      token,
    });
  },
};

export default PublicEndpoints;
