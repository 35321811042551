import { useState } from "react";
import { IconButton, Menu, MenuItem } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";

import { IActionsMenuProps } from "./types";

const ActionsMenu = ({ options }: IActionsMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: Event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  if (!options || options.length === 0) {
    return <div />;
  }

  return (
    <div>
      <IconButton
        aria-controls="basic-menu"
        aria-haspopup="true"
        aria-expanded={isMenuOpen ? "true" : undefined}
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        onClick={(event) => event.stopPropagation()}
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {options.map(({ label, onClick }) => (
          <MenuItem
            key={label}
            onClick={(event) => {
              event.preventDefault();
              onClick();
              setAnchorEl(null);
            }}
          >
            {label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default ActionsMenu;
