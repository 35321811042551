import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Paper,
  TableContainer,
  TablePagination,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Card,
  Typography,
} from "@material-ui/core";

import FilterHistory from "../../interfaces/FilterHistory";
import HistoryModel from "../../interfaces/HistoryModel";
import HistoryApi from "../../resources/history";
import addToast from "../../utils/toast";
import Button from "../../components/Button";
import Filter from "../../components/Filter";
import HistoryFilterComponent from "./components/HistoryFilterComponent/HistoryFilterComponent";
import HistoryRow from "./components/HistoryRow/HistoryRow";

const History: React.FC = () => {
  const [page, setPage] = useState(0);
  const [filter, setFilter] = useState({} as { [key: string]: string });
  const [perPage, setPerPage] = useState(10);
  const [totalFound, setTotalFound] = useState(0);
  const [historyData, setHistoryData] = useState([] as HistoryModel[]);

  const canCleanFilters = useMemo(
    () => Object.keys(filter).some((key) => Boolean(filter[key])),
    [filter]
  );

  const handleClearButtonClick = useCallback(() => {
    setFilter({});
    setHistoryData([]);
  }, []);

  const handlePageChange = useCallback(
    (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
      setPage(newPage);
    },
    []
  );

  const handlePerPageChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setPerPage(parseInt(event.target.value, 10));
      setPage(0);
    },
    []
  );

  const getHistory = useCallback(
    (userFilters: FilterHistory, page: number, perPage: number) => {
      try {
        HistoryApi.getAll(userFilters, page, perPage)
          .then((response) => {
            if (response?.data?.items) {
              const history = response.data.items as HistoryModel[];
              const total = response.data.total as number;

              setHistoryData(history);
              setTotalFound(total);
            }
          })
          .catch((error) => {
            const { data } = error;
            if (data) {
              addToast(data.message, { type: "error" });
            }
          });
      } catch (error: any) {
        addToast(error.message, { type: "error" });
      }
    },
    [filter]
  );

  useEffect(() => {
    getHistory(filter, page, perPage);
  }, [page, perPage]);

  return (
    <div className="pa8 pa24-l">
      <Card className="pa16">
        <div className="flex justify-between mb8">
          <div
            className="flex justify-center items-center"
            style={{ height: 36 }}
          >
            <h2>Envio de e-mails e whatsapp</h2>
          </div>
          <div className="flex">
            {canCleanFilters && (
              <Button
                variant="text"
                buttonValue="Limpar Filtros"
                onClick={handleClearButtonClick}
              />
            )}
          </div>
        </div>

        <Filter
          filter={filter}
          setFilter={setFilter}
          FilterComponent={HistoryFilterComponent}
          onFilter={() => {
            getHistory(filter, page, perPage);
          }}
        />
      </Card>
      <Card className="pa16 mt24">
        {historyData.length ? (
          <>
            <Typography variant="h6">
              Registros Encontrados: {totalFound}
            </Typography>
            <br />
            <TableContainer component={Paper}>
              <Table aria-label="collapsible table">
                <TableHead>
                  <TableRow>
                    <TableCell>Protocolo</TableCell>
                    <TableCell>Produto</TableCell>
                    <TableCell>Vencimento</TableCell>
                    <TableCell>Nome do Titular</TableCell>
                    <TableCell>Tipo de Contato</TableCell>
                    <TableCell>Mensagem</TableCell>
                    <TableCell>Contato em</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {historyData.map((history) => (
                    <HistoryRow key={history.id} history={history} />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <div className="w-100 flex justify-end">
              <TablePagination
                style={{ border: "none" }}
                labelRowsPerPage="Linhas por página"
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                colSpan={13}
                count={totalFound}
                rowsPerPage={perPage}
                page={page}
                SelectProps={{
                  inputProps: { "aria-label": "rows per page" },
                  native: true,
                }}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handlePerPageChange}
              />
            </div>
          </>
        ) : (
          <div className="mt-24">
            <Typography variant="body1">
              Nenhum histórico encontrado...
            </Typography>
          </div>
        )}
      </Card>
    </div>
  );
};

export default History;
