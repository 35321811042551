import { FormControlLabel, Switch, TablePagination } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePaginationActions from "@material-ui/core/TablePagination/TablePaginationActions";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import React, { useState } from "react";
import EmissionGrid from "../../interfaces/EmissionGrid";
import CustomRow from "./components/CustomRow";
import styles from "./style.module.css";
import { ProfileOptions } from "../../utils/ProfileOptions";

interface Props {
  page: number;
  perPage: number;
  total: number;
  data: EmissionGrid[];
  userProfile: ProfileOptions;
  handlePageChange: (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => void;
  handlePerPageChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
}

const EmissionTable = ({
  page,
  perPage,
  total = 0,
  data = [],
  userProfile,
  handlePageChange,
  handlePerPageChange,
}: Props) => {
  const [displayDetails, setDisplayDetails] = useState(false);

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    setDisplayDetails(!displayDetails);
  };

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell className="pa8">Protocolo</TableCell>
            <TableCell className="pa8">Razão Social</TableCell>
            <TableCell className="pa8">Documento</TableCell>
            <TableCell className="pa8">Titular Nome</TableCell>
            <TableCell className="pa8">Titular CPF</TableCell>
            <TableCell className="pa8">Produto</TableCell>
            <TableCell className="pa8">AVP</TableCell>
            <TableCell className="pa8">AVP CPF</TableCell>
            {/* <TableCell className="pa8" align="center">Data AVP</TableCell> */}
            <TableCell className="pa8" align="center">
              Início Validade
            </TableCell>
            <TableCell className="pa8" align="center">
              Fim Validade
            </TableCell>
            <TableCell className="pa8" align="center">
              Status
            </TableCell>
            <TableCell className="pa8" align="center">
              Situação
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row: EmissionGrid) => (
            <CustomRow
              key={row.protocolo}
              row={row}
              displayDetails={displayDetails}
              disableTextSelection={userProfile !== ProfileOptions.ADMIN}
            />
          ))}
        </TableBody>
      </Table>
      <div className={styles.footer}>
        {userProfile !== ProfileOptions.PADRAO && (
          <FormControlLabel
            value="end"
            control={
              <Switch
                checked={displayDetails}
                onChange={handleSwitchChange}
                name="checkedA"
                inputProps={{ "aria-label": "secondary checkbox" }}
                color="primary"
              />
            }
            label={
              <Typography variant="body2">
                Exibir Detalhes do Titular
              </Typography>
            }
            labelPlacement="end"
          />
        )}

        {userProfile === ProfileOptions.PADRAO && <div className="w100"></div>}

        <TablePagination
          style={{ border: "none" }}
          labelRowsPerPage="Linhas por página"
          rowsPerPageOptions={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
          colSpan={13}
          count={total}
          rowsPerPage={perPage}
          page={page}
          SelectProps={{
            inputProps: { "aria-label": "rows per page" },
            native: true,
          }}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handlePerPageChange}
          ActionsComponent={TablePaginationActions}
        />
      </div>
    </TableContainer>
  );
};

export default EmissionTable;
