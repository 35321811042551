import _get from "lodash/get";
import { TableBody, TableCell, TableRow } from "@material-ui/core";

import { CustomElementType } from "../../types";
import ActionsMenu from "../ActionsMenu";
import { ITBodyProps } from "./types";
import { generateStaticRows } from "./utils";
import { Skeleton } from "@material-ui/lab";
import { minimizeString } from "../../../../utils/minimizeString";

const TBody = <T extends object>({
  data,
  cells,
  rowKeyProperty,
  numberOfLoadingRows,
  onRowClick,
  customElements,
  actions,
}: ITBodyProps<T>) => {
  if (data) {
    return (
      <TableBody>
        {data.map((row) => {
          const customFields = customElements ? customElements(row) : {};

          const rowOptions = actions && (
            <TableCell key="actions" align="center">
              <ActionsMenu options={actions(row)} />
            </TableCell>
          );

          return (
            <TableRow
              key={_get(row, rowKeyProperty)}
              onClick={() =>
                typeof onRowClick === "function" ? onRowClick(row) : null
              }
              hover
            >
              {cells.map(
                ({ dataObjectKey, align, customMaxLengthOfCellStrings }) => {
                  const customField = _get(
                    customFields,
                    dataObjectKey
                  ) as CustomElementType;

                  const defaultField = !customField
                    ? _get(row, dataObjectKey) || "Não informado"
                    : customField;

                  return (
                    <TableCell key={dataObjectKey} align={align ?? "left"}>
                      {customField || (
                        <span title={String(defaultField)}>
                          {minimizeString(
                            String(defaultField),
                            customMaxLengthOfCellStrings
                          )}
                        </span>
                      )}
                    </TableCell>
                  );
                }
              )}

              {rowOptions}
            </TableRow>
          );
        })}
      </TableBody>
    );
  }

  return (
    <TableBody>
      {generateStaticRows(numberOfLoadingRows ?? 10).map((keyIndex) => (
        <TableRow
          key={keyIndex}
          tabIndex={-1}
          selected={false}
          aria-checked={false}
        >
          {cells.map(({ dataObjectKey, align }) => (
            <TableCell key={dataObjectKey} align={align ?? "left"}>
              <Skeleton animation="wave" height={16} />
            </TableCell>
          ))}
          {actions && (
            <TableCell key="actions" align="center">
              <Skeleton animation="wave" height={16} />
            </TableCell>
          )}
        </TableRow>
      ))}
    </TableBody>
  );
};

export default TBody;
