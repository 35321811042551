import { memo } from 'react';
import cx from 'classnames';

import styles from './style.module.css';
import { FormControl, FormHelperText, IconButton, InputLabel, MenuItem, Select as MuiSelect } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close'
import withField from '../Field';
import SelectOption from '../../interfaces/SelectOption';

interface Props {
    label: string;
    options: SelectOption[];
    onChange: (event: any) => void;
    name: string;
    error?: string;
    value: number | string;
    onBlur?: () => void;
    touched?: false;
    setFieldValue?: (prop: string, value: any) => void;
}

const _Select = ({
  label,
  options,
  onChange,
  name,
  error,
  value,
  onBlur,
  touched,
  setFieldValue,
}: Props) => {
  const hasError = Boolean(error) && touched;
  return (
    <FormControl
      variant="outlined"
      className={cx('w-100 relative', styles.container)}
      error={hasError}
    >
      {Boolean(value) && (
        <IconButton
          size="small"
          className={styles.deleteButton}
          onClick={(ev) => {
            ev.stopPropagation();
            onChange({
              target: { value: '' },
              persist: () => {},
            });
            if(setFieldValue) {
                setFieldValue(name, '');
            }
          }}
          style={{
            position: 'absolute',
            top: 15,
            right: 30,
            zIndex: 300,
          }}
        >
          <CloseIcon
            style={{
              width: 20,
              height: 20,
            }}
          />
        </IconButton>
      )}
      <InputLabel>{label}</InputLabel>
      <MuiSelect
        onChange={onChange}
        label={label}
        name={name}
        value={value || ""}
        inputProps={{ style: { height: 42 } }}
        className={styles.select}
        onBlur={onBlur}
      >
        {options.map((opt: any) => (
          <MenuItem key={opt.value} value={opt.value} disabled={opt.disabled}>{opt.text}</MenuItem>
        ))}
      </MuiSelect>
      {hasError && (
        <FormHelperText>
            {error}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export const Select = memo(_Select);

export default withField(memo(_Select));
