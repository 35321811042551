import React, { useCallback, useMemo, useState } from "react";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import MuiButton from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Button from "../../../../components/Button";
import { Select } from "../../../../components/Select";
import HistoryModel from "../../../../interfaces/HistoryModel";
import { getActionOptions } from "./HistoryActionDialog.utils";
import { Input } from "../../../../components/Input";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

interface Props {
  open: boolean;
  history: HistoryModel;
  handleClose: () => void;
  handleSubmit: (
    id: number,
    actionToTake: string,
    actionValue?: string | Date
  ) => any;
}

const HistoryActionDialog: React.FC<Props> = ({
  open,
  history,
  handleClose,
  handleSubmit,
}) => {
  const [actionToTake, setActionToTake] = useState("");
  const [valueToChange, setValueToChange] = useState<string | Date>("");

  const actionOptions = getActionOptions(history?.acao);

  const handleSelectChange = (ev: React.ChangeEvent<HTMLSelectElement>) => {
    setActionToTake(ev.target.value);
    setValueToChange(ev.target.value === "tentarNovamente" ? "1" : "");
  };

  const handleInputChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setValueToChange(ev.target.value);
  };

  const [showInput, inputLabel] = useMemo(() => {
    if (actionToTake === "telefone") {
      return [true, "Novo número"];
    }

    if (actionToTake.includes("email")) {
      return [true, "Novo e-mail"];
    }

    return [false, ""];
  }, [actionToTake]);

  const updateHistoryTakingAction = useCallback(() => {
    if (history.id) {
      handleSubmit(history.id, actionToTake, valueToChange);
    }
  }, [actionToTake, handleSubmit, history?.id, valueToChange]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
    >
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        Realizar ação corretiva
      </DialogTitle>
      <DialogContent dividers>
        <Typography>
          Selecione a ação que deseja realizar com esta tentativa de contato
          referente a uma renovação de emissão:
        </Typography>
        <div className="mt16">
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Select
                label="Ação"
                name="action"
                value={actionToTake}
                options={actionOptions}
                onChange={handleSelectChange}
              />
            </Grid>
            {showInput && (
              <Grid item xs={6}>
                <Input
                  name="valueToChange"
                  value={valueToChange}
                  placeholder={inputLabel}
                  onChange={handleInputChange}
                  mask={
                    actionToTake === "phone" ? "(99) 99999-9999" : undefined
                  }
                />
                {actionToTake === "phone" && (
                  <span className="gray f12 pl12">*No formato xx9xxxxxxxx</span>
                )}
              </Grid>
            )}
          </Grid>
        </div>
      </DialogContent>
      <DialogActions>
        <MuiButton autoFocus onClick={handleClose} color="primary">
          Cancelar
        </MuiButton>
        <Button buttonValue="Enviar" onClick={updateHistoryTakingAction} />
      </DialogActions>
    </Dialog>
  );
};

export default HistoryActionDialog;
