const headers = [
  {
    label: "Nome",
  },
  {
    label: "Email",
  },
  {
    label: "CPF",
  },
  {
    label: "Perfil",
  },
  {
    label: "Criado Em",
  },
  {
    label: "Alterado Em",
  },
  {
    label: "Situação",
  },
  {
    label: "Status",
  },
];

const cells = [
  { dataObjectKey: "nome" },
  { dataObjectKey: "email" },
  { dataObjectKey: "formatedCpf" },
  { dataObjectKey: "perfil" },
  { dataObjectKey: "criadoEm" },
  { dataObjectKey: "alteradoEm" },
  { dataObjectKey: "ativo" },
  { dataObjectKey: "status" },
];

const rowKeyProperty = "id";

export { headers, cells, rowKeyProperty };
