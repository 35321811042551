import { useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Card, Typography } from '@material-ui/core';

import addToast from '../../utils/toast';
import Button from '../../components/Button';
import { Input } from '../../components/Input';
import logo from '../../assets/img/logo-certec.png';
import PublicEndpoints from '../../resources/public';
import { EmissionStatusResponse } from './PublicEmissionStatus.types';
import { CheckCircle } from '@material-ui/icons';

export const PublicEmissionStatus: React.FC = () => {
  const recaptchaRef = useRef<any>(null);
  const [protocolo, setProtocolo] = useState('');
  const [captchaToken, setCaptchaToken] = useState('');
  const [result, setResult] = useState<EmissionStatusResponse>(
    {} as EmissionStatusResponse
  );

  const handleChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setProtocolo(ev.target.value.replace(/\D/g, '').slice(0, 10));
  };

  const handleSubmit = () => {
    if (!captchaToken) {
      return;
    }

    const parsed = protocolo.replace(/_/g, '');
    if (!parsed?.length || parsed.replace(/_/g, '').length < 8) {
      return;
    }

    if (result.numeroReferencia === +parsed) {
      return;
    }

    PublicEndpoints.getProtocolStatus(+parsed, captchaToken)
      .then((res) => {
        setResult(res.data?.status);
      })
      .catch((error) => {
        setResult({ numeroReferencia: +parsed, situacao: '' });
        addToast(error?.data?.message || error?.message, { type: 'error' });
      })
      .finally(() => {
        if (recaptchaRef.current) {
          setCaptchaToken('');
          recaptchaRef.current?.reset();
        }
      });
  };

  return (
    <div className="pa8 w-100 h-100 flex flex-column justify-center items-center">
      <img
        src={logo}
        alt="Logo BrasilCertec"
        className="measure-narrow pa8 mb32"
      />

      <div className="flex flex-column">
        <Card className="pa16">
          <Typography className="w-fit-contenti" variant="h6">
            Consultar Status do Protocolo
          </Typography>

          <div className="flex mt16 w-fit-contenti">
            <div className="w-177pi">
              <Input
                disableFullWidth
                value={protocolo}
                onChange={handleChange}
                placeholder="Nº Protocolo"
                type='number'
              />
            </div>
            <Button
              className="ml16i"
              buttonValue="Pesquisar"
              onClick={handleSubmit}
              disabled={
                !captchaToken ||
                protocolo?.replace(/_/g, '').length < 8 ||
                +protocolo === result.numeroReferencia
              }
            />
          </div>
          {+protocolo !== result.numeroReferencia && (
            <div className="mt16 flex justify-center">
              <ReCAPTCHA
                size="normal"
                badge="inline"
                ref={recaptchaRef}
                sitekey={process.env.REACT_APP_RECAPTCHA_KEY as string}
                onErrored={() => {
                  setCaptchaToken('');
                }}
                onExpired={() => {
                  setCaptchaToken('');
                }}
                onChange={(token) => {
                  setCaptchaToken(token as string);
                }}
              />
            </div>
          )}
        </Card>

        {result?.situacao && (
          <Card className="pa16 mt16">
            <div className="flex items-center mb8">
              <CheckCircle className="dark-green mr8" />
              <Typography className="ll8 w-fit-contenti" variant="body2">
                Status do protocolo <b>{result.numeroReferencia}</b>:
              </Typography>
            </div>

            <Typography className="pl32 w-fit-contenti" variant="body1">
              <b>{result.situacao}</b>
            </Typography>
          </Card>
        )}
      </div>
    </div>
  );
};
