import { Pagination } from "@material-ui/lab";
import { ITFooterProps } from "../../types";

const TFooter = ({ page, count, onChange }: ITFooterProps) => (
  <Pagination
    variant="outlined"
    color="primary"
    size={"medium"}
    page={page}
    count={count}
    onChange={onChange}
  />
);

export default TFooter;
