import { useCallback, useMemo, useState } from "react";
import { Card, Grid } from "@material-ui/core";
import { useConfirm } from "material-ui-confirm";
import { FaUserCheck, FaUserLock, FaUserTimes } from "react-icons/fa";

import { IUser } from "./types";
import addToast from "../../utils/toast";
import Button from "../../components/Button";
import Filter from "../../components/Filter";
import Usuario from "../../resources/usuarios";
import FilterComponent from "./components/Filter";
import ColorLabel from "../../components/ColorLabel";
import formatCpfCnpj from "../../utils/formatCpfCnpj";
import CustomTable from "../../components/CustomTable";
import FilterUsers from "../../interfaces/FilterUsers";
import { headers, cells, rowKeyProperty } from "./utils/tableConfig";
import { useAuth } from "../../hooks/auth";

interface FilterState {
  [key: string]: string;
}

const Usuarios = () => {
  const [usuarios, setUsuarios] = useState([] as IUser[]);
  const [filter, setFilter] = useState<FilterState>({} as FilterState);
  const {
    authData: { user },
  } = useAuth();

  const confirm = useConfirm();

  const canCleanFilters = useMemo(
    () => Object.keys(filter).some((key) => Boolean(filter[key])),
    [filter]
  );

  const handleClearButtonClick = () => {
    setFilter({});
  };

  const getUsers = useCallback((filters: FilterUsers) => {
    try {
      Usuario.getAll(filters)
        .then((response: any) => {
          const {
            data: { usuarios },
          } = response;

          if (usuarios.length === 0 && Object.keys(filters).length > 0) {
            addToast("Nenhum resultado encontrado!", { type: "error" });
          }

          setUsuarios(usuarios);
        })
        .catch((error: any) => {
          const { data } = error;
          if (data) {
            addToast(data.message, { type: "error" });
          }
        });
    } catch (error: any) {
      addToast(error.message, { type: "error" });
    }
  }, []);

  const customElements = ({
    cpf,
    ativo,
    loginBloqueado,
    verificado,
  }: IUser) => ({
    formatedCpf: formatCpfCnpj(cpf),
    ativo: ativo ? (
      <ColorLabel label="Ativo" color="bg-dark-green" />
    ) : (
      <ColorLabel label="Inativo" color="bg-dark-gray" />
    ),
    status: (
      <Grid container spacing={1}>
        {verificado ? (
          <Grid item>
            <Card elevation={3}>
              <div className="flex pa4">
                <FaUserCheck color="green" title="Email verificado" size={20} />
              </div>
            </Card>
          </Grid>
        ) : (
          <Grid item>
            <Card elevation={3}>
              <div className="flex pa4">
                <FaUserTimes
                  color="red"
                  title="Email não verificado"
                  size={20}
                />
              </div>
            </Card>
          </Grid>
        )}
        {loginBloqueado && (
          <Grid item>
            <Card elevation={3}>
              <div className="flex pa4">
                <FaUserLock color="#444" title="Login bloqueado" size={20} />
              </div>
            </Card>
          </Grid>
        )}
      </Grid>
    ),
  });

  const updateUser = (userId: string, data: any) =>
    Usuario.update({ id: userId, ...data })
      .then((res: any) => {
        addToast("Operação realizada com sucesso!", { type: "success" });
        getUsers(filter);
      })
      .catch((err: any) =>
        addToast(err.data.message, {
          type: "error",
        })
      );

  const actions = (data?: IUser) => {
    if (!data || data.perfil === "Administrador") {
      return [];
    }

    if (
      user.id === data.id ||
      user.perfil.toLowerCase() === data.perfil.toLowerCase()
    ) {
      return [];
    }

    const { ativo, loginBloqueado } = data;

    const rowActions = [
      {
        label: ativo ? "Desativar" : "Ativar",
        onClick: () =>
          confirm({
            description: `Realmente deseja ${
              ativo ? "DESATIVAR" : "ATIVAR"
            } este usuário?`,
          })
            .then(() => updateUser(data.id, { ativo: !data.ativo }))
            .catch(() => {}),
      },
    ];

    if (loginBloqueado) {
      rowActions.push({
        label: "Desbloquear Login",
        onClick: () =>
          confirm({
            description: "Realmente deseja DESBLOQUEAR O LOGIN deste usuário?",
          })
            .then(() => updateUser(data.id, { desbloquearLogin: true }))
            .catch(() => {}),
      });
    }

    return rowActions;
  };

  return (
    <div className="pa8 pa24-l">
      <Card className="pa16">
        <div className="flex justify-between mb8">
          <div
            className="flex justify-center items-center"
            style={{ height: 36 }}
          >
            <h2>Usuários</h2>
          </div>
          <div className="flex">
            {canCleanFilters && (
              <Button
                buttonValue="Limpar Filtros"
                onClick={handleClearButtonClick}
                variant="text"
              />
            )}
          </div>
        </div>
        <Filter
          filter={filter}
          setFilter={setFilter}
          FilterComponent={FilterComponent}
          onFilter={() => {
            getUsers(filter);
          }}
        />
      </Card>
      <div className="pa20 mt24">
        <CustomTable
          data={usuarios}
          headers={headers}
          cells={cells}
          rowKeyProperty={rowKeyProperty}
          customElements={customElements}
          actions={actions}
        />
      </div>
    </div>
  );
};

export default Usuarios;
