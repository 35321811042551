import { Grid } from "@material-ui/core";
import { Input } from "../../../../components/Input";
import { Select } from "../../../../components/Select";
import SelectOption from "../../../../interfaces/SelectOption";

interface Props {
  filter: any;
  onFilter: any;
}

const roleOptions: SelectOption[] = [
  { text: "Administrador", value: "admin" },
  { text: "Gerente", value: "gerente" },
  { text: "Padrão", value: "padrao" },
];

const situation: SelectOption[] = [
  { text: "Ativo", value: "true" },
  { text: "Inativo", value: "false" },
];

const yesOrNo: SelectOption[] = [
  { text: "Sim", value: "true" },
  { text: "Não", value: "false" },
];

const Filter = ({ filter, onFilter }: Props) => (
  <div className="flex-grow-1">
    <Grid container spacing={2}>
      <Grid item xs={12} md={3}>
        <Input
          placeholder="Nome"
          value={filter.nome}
          onChange={onFilter("nome")}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <Input
          placeholder="Email"
          value={filter.email}
          onChange={onFilter("email")}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <Input
          placeholder="CPF"
          value={filter.cpf}
          onChange={onFilter("cpf")}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <Select
          label="Perfil"
          options={roleOptions}
          value={filter.perfil}
          onChange={onFilter("perfil")}
          name="perfil"
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <Select
          label="Situação"
          options={situation}
          value={filter.ativo}
          onChange={onFilter("ativo")}
          name="ativo"
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <Select
          label="Email Verificado"
          options={yesOrNo}
          value={filter.emailVerificado}
          onChange={onFilter("emailVerificado")}
          name="emailVerificado"
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <Select
          label="Login Bloqueado"
          options={yesOrNo}
          value={filter.loginBloqueado}
          onChange={onFilter("loginBloqueado")}
          name="loginBloqueado"
        />
      </Grid>
    </Grid>
  </div>
);

export default Filter;
