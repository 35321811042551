import { FilterBusiness } from "../interfaces/FilterBusiness";
import api from "./api";

const baseUrl = "/empresa";

const Empresa = {
  getAll: (filter: FilterBusiness, page: number, perPage: number) => {
    return api.get(baseUrl, {
      params: {
        ...filter,
        page,
        perPage,
        cnpj: filter.cnpj ? filter.cnpj.replace(/[^0-9]/g, "") : undefined,
        telefone: filter.telefone
          ? filter.telefone.replace(/[^0-9]/g, "")
          : undefined,
        dtInicio: filter.dtInicio
          ? filter.dtInicio.format("yyyy-MM-DD")
          : undefined,
        dtFim: filter.dtFim ? filter.dtFim.format("yyyy-MM-DD") : undefined,
      },
    });
  },
  createEmpresas: (empresas: any[]) =>
    api.post(`${baseUrl}/to-process`, empresas),
  copiarTelefoneDaEmpresa: (id: number) =>
    api.get(`${baseUrl}/${id}/phone-number`),
};

export default Empresa;
