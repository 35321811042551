import axios from 'axios';

const { REACT_APP_API_URL, REACT_APP_ENV } = process.env;

const api = axios.create({
  baseURL:
    REACT_APP_ENV === 'dev' ? 'http://localhost:3333' : REACT_APP_API_URL,
});

api.interceptors.request.use((config) => {
  const newConfig = config;
  const token = localStorage.getItem('@Auth/access_token');

  if (
    !token &&
    config.url !== '/auth' &&
    !config.url?.includes('/token') &&
    !config.url?.includes('/public')
  ) {
    throw new axios.Cancel('no token');
  }

  newConfig.headers.Authorization = token;
  return newConfig;
});

export default api;
