import { useState, useCallback, useMemo } from "react";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import InfoIcon from "@material-ui/icons/Info";
import addToast from "../../../../utils/toast";
import { originColumnNames, originOptions } from "./UploadForm.utils";
import Button from "../../../../components/Button";
import { UploadFormProps } from "./UploadForm.types";
import { Select } from "../../../../components/Select";
import { OriginType } from "../../../../interfaces/UploadEmissionData";
import { IconButton, Popover, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: theme.spacing(1),
  },
}));

const UploadForm: React.FC<UploadFormProps> = ({
  submitForm,
  uploadState,
  setUploadState,
  loading = false,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const classes = useStyles();
  const open = Boolean(anchorEl);

  const toggleAnchorEl = (event: any) => {
    setAnchorEl((prevState) =>
      Boolean(prevState) ? null : event.currentTarget
    );
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleFileChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const files = event.target.files;

      if (!files) {
        return;
      }

      setUploadState((prevState) => ({
        ...prevState,
        files: Array.from(files),
      }));
    },
    [setUploadState]
  );

  const handleOriginChange = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      setUploadState((prevState) => ({
        ...prevState,
        origin: event.target.value as OriginType,
      }));
    },
    [setUploadState]
  );

  const disableSubmit = useMemo(() => {
    const { origin, files } = uploadState;

    if (!origin) {
      return true;
    }

    if (!Boolean(files?.length)) {
      return true;
    }

    return loading;
  }, [uploadState, loading]);

  const handleSubmit = useCallback(() => {
    if (!disableSubmit) {
      const { files } = uploadState;
      let hasError = false;

      files.forEach((file) => {
        if (file.type !== "text/csv") {
          addToast(`O arquivo "${file.name}" deve ser do tipo csv (text/csv)`, {
            type: "error",
          });

          hasError = true;
        }
      });

      if (!hasError) {
        submitForm(uploadState);
      }
    } else {
      addToast("Verifique se os campos foram preenchidos corretamente!", {
        type: "error",
      });
    }
  }, [disableSubmit, uploadState, submitForm]);

  const columnNames: string[] = useMemo(
    () => (originColumnNames[uploadState.origin as any] || []) as string[],
    [uploadState.origin]
  );

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid xs={12} md={4} item container alignItems="center" spacing={2}>
        <Grid item xs>
          <Select
            name="origin"
            options={originOptions}
            label="Modelo da Planilha"
            value={uploadState.origin}
            onChange={handleOriginChange}
          />
        </Grid>
        <Grid item>
          <div>
            <IconButton
              color="primary"
              aria-label="upload picture"
              onClick={toggleAnchorEl}
            >
              <InfoIcon />
            </IconButton>
            <Popover
              open={open}
              disableRestoreFocus
              id="mouse-over-popover"
              className={classes.popover}
              onClose={handlePopoverClose}
              classes={{
                paper: classes.paper,
              }}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <div className="pa16">
                <h3>Nome das colunas</h3>
                <p className="gray pt4 f12">* Campos obrigatórios</p>
                <p className="gray pt4">
                  O arquivo de upload deve estar no formato ".csv" <br />e todos
                  os valores devem ser "textos" e não "números."
                </p>
                <ol className="mt8 use-zeros">
                  {columnNames.map((columnName) => (
                    <li key={columnName} className="ml24">
                      {columnName}
                    </li>
                  ))}
                </ol>
              </div>
            </Popover>
          </div>
        </Grid>
      </Grid>
      <Grid xs={12} md item>
        <input type="file" multiple onChange={handleFileChange} accept=".csv" />
      </Grid>
      <Grid xs={12} md={2} item>
        <Button
          buttonValue={
            loading ? (
              <CircularProgress style={{ width: 24, height: 24 }} />
            ) : (
              "Upload"
            )
          }
          onClick={handleSubmit}
          disabled={disableSubmit}
        />
      </Grid>
    </Grid>
  );
};

export default UploadForm;
