import { Grid } from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";

import { Input } from "../../../../components/Input";
import { Select } from "../../../../components/Select";
import SelectOption from "../../../../interfaces/SelectOption";
import FilterHistory from "../../../../interfaces/FilterHistory";

interface Props {
  filter: FilterHistory;
  onFilter: any;
}

const HistoryFilterComponent = ({ filter, onFilter }: Props) => {
  const statusOptions: SelectOption[] = [
    { text: "Sucesso", value: "SUCESSO" },
    { text: "Erro", value: "ERRO" },
  ];

  return (
    <div className="flex-grow-1">
      <Grid container spacing={2}>
        <Grid xs={12} sm={6} md={4} lg item>
          <Input
            placeholder="Protocolo"
            value={filter.protocolo}
            onChange={onFilter("protocolo")}
          />
        </Grid>
        <Grid xs={12} sm={6} md={4} lg item>
          <Select
            label="Status"
            options={statusOptions}
            value={filter.status as string}
            onChange={onFilter("status")}
            name="status"
          />
        </Grid>
        <Grid xs={12} sm={6} md={4} lg item>
          <Input
            placeholder="CPF"
            value={filter.cpf}
            onChange={onFilter("cpf")}
            mask="999.999.999-99"
          />
        </Grid>
        <Grid xs={12} sm={6} md={4} lg item>
          <Input
            placeholder="CNPJ"
            value={filter.cnpj}
            onChange={onFilter("cnpj")}
            mask="99.999.999/9999-99"
          />
        </Grid>
        <Grid xs={12} sm={6} md={4} lg item>
          <KeyboardDatePicker
            autoOk
            clearable
            inputVariant="outlined"
            label="Início Período"
            format="DD/MM/yyyy"
            onChange={onFilter("startDate")}
            invalidDateMessage={"Data inválida"}
            value={filter.startDate ?? null}
            inputProps={{ style: { height: 56 } }}
            fullWidth
          />
        </Grid>
        <Grid xs={12} sm={6} md={4} lg item>
          <KeyboardDatePicker
            autoOk
            clearable
            inputVariant="outlined"
            label="Fim Período"
            format="DD/MM/yyyy"
            onChange={onFilter("endDate")}
            invalidDateMessage={"Data inválida"}
            value={filter.endDate ?? null}
            inputProps={{ style: { height: 56 } }}
            fullWidth
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default HistoryFilterComponent;
