import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { options } from '../../assets/json/menuOptions';
import Menu from '../Menu';
import Topbar from '../Topbar';

import styles from './style.module.css';

interface Props {
    children: React.ReactNode;
}

const MenuWithTopBar = ({
  children,
}: Props) => {
  const  location = useLocation();
  const [open, setOpen] = useState(false);

  const handleDrawer = () => {
    setOpen(!open);
  };

  return (
    <div className={styles.container}>
      <Topbar handleDrawer={handleDrawer} open={open} />
      <div className={styles.row}>
        <Menu
          handleDrawer={handleDrawer}
          open={open}
          options={options}
          activatedRoute={location.pathname}
        />
        <div className={styles.children}>
          {children}
        </div>
      </div>
    </div>
  );
}

export default MenuWithTopBar;
