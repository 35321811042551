import { useState, useMemo, useCallback } from "react";
import Accordion from "@material-ui/core/Accordion";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Grid from "@material-ui/core/Grid";
import AccordionSummary from "@material-ui/core/AccordionSummary";

import {
  ResultSectionData,
  UploadResultContentProps,
} from "./UploadResultContent.types";
import { UploadResultSection } from "../UploadResultSection";
import { UploadCsvResult } from "../../../../interfaces/UploadCsvResult";
import CircleLabel from "../../../../components/CircleLabel";
import ColorLabel from "../../../../components/ColorLabel";

const UploadResultContent: React.FC<UploadResultContentProps> = ({
  result,
}) => {
  const [expanded, setExpanded] = useState(false);

  const [sections, mappedSections] = useMemo(() => {
    const res = [] as ResultSectionData[];

    const resultsOfStatus = result.results.reduce((acc, item) => {
      if (!acc[item.status]) {
        return {
          ...acc,
          [item.status]: [item],
        };
      }

      return {
        ...acc,
        [item.status]: [item, ...acc[item.status]],
      };
    }, {} as { [key: string]: UploadCsvResult[] });

    if (resultsOfStatus["S"]) {
      res.push({
        title: "Sucesso",
        legend: "Emissões inseridas com sucesso",
        data: resultsOfStatus["S"],
      });
    }

    if (resultsOfStatus["E"]) {
      res.push({
        title: "Erro",
        legend: "Emissões com problemas",
        data: resultsOfStatus["E"],
      });
    }

    if (resultsOfStatus["A"]) {
      res.push({
        title: "Atualizadas",
        legend: "Emissões que já existiam e foram atualizadas",
        data: resultsOfStatus["A"],
      });
    }

    if (resultsOfStatus["D"]) {
      res.push({
        title: "Duplicadas",
        legend: "Emissões que já foram inseridas anteriormente",
        data: resultsOfStatus["D"],
      });
    }

    return [res, resultsOfStatus];
  }, [result.results]);

  const numberOfSuccesses = useMemo(
    () => mappedSections["S"]?.length || 0,
    [mappedSections]
  );

  const numberOfErrors = useMemo(
    () => mappedSections["E"]?.length || 0,
    [mappedSections]
  );

  const numberOfUpdates = useMemo(
    () => mappedSections["A"]?.length || 0,
    [mappedSections]
  );

  const numberOfDuplicates = useMemo(
    () => mappedSections["D"]?.length || 0,
    [mappedSections]
  );

  const totalEmissions = useMemo(
    () =>
      numberOfSuccesses + numberOfErrors + numberOfUpdates + numberOfDuplicates,
    [numberOfDuplicates, numberOfErrors, numberOfSuccesses, numberOfUpdates]
  );

  const handleExpandAccordion = useCallback(
    (ev: any) => {
      if (!Boolean(result.results?.length)) {
        ev.stopPropagation();
      } else {
        setExpanded((prevState) => !prevState);
      }
    },
    [result.results?.length]
  );

  return (
    <Accordion
      key={result.fileName}
      expanded={expanded}
      onChange={handleExpandAccordion}
    >
      <AccordionSummary
        id={result.fileName}
        aria-controls="panel1bh-content"
        style={{
          pointerEvents: Boolean(result.results?.length) ? "initial" : "none",
        }}
        expandIcon={
          <ExpandMoreIcon
            style={{
              visibility: Boolean(result.results?.length)
                ? "visible"
                : "hidden",
            }}
          />
        }
      >
        <Grid item xs={12} container spacing={2} alignItems="center">
          <Grid item xs>
            <h3>{result.fileName}</h3>
          </Grid>
          {Boolean(numberOfSuccesses) && (
            <Grid item>
              <CircleLabel
                color="bg-success"
                label={numberOfSuccesses}
                title="Número de sucessos"
              />
            </Grid>
          )}
          {Boolean(numberOfErrors) && (
            <Grid item>
              <CircleLabel
                color="bg-primary-red"
                label={numberOfErrors}
                title="Número de erros"
              />
            </Grid>
          )}
          {Boolean(numberOfUpdates) && (
            <Grid item>
              <CircleLabel
                color="bg-blue"
                label={numberOfUpdates}
                title="Número de atualizações"
              />
            </Grid>
          )}
          {Boolean(numberOfDuplicates) && (
            <Grid item>
              <CircleLabel
                color="bg-yellow"
                label={numberOfDuplicates}
                title="Número de duplicados"
              />
            </Grid>
          )}
          <Grid item>
            <ColorLabel
              color="bg-wild-sand"
              textColor="black"
              label={`Total: ${totalEmissions}`}
            />
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <div className="w-100 flex flex-column">
          {sections.map((section) => (
            <UploadResultSection key={section.title} {...section} />
          ))}
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default UploadResultContent;
