import { toast, TypeOptions } from 'react-toastify';

interface Props {
    type: TypeOptions;
}

const addToast = (message: string, props?: Props) => {
    toast(message, {
        type: 'info',
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        ...props,
    });
}

export default addToast;
