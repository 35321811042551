import SelectOption from "../../../../interfaces/SelectOption";

export const getActionOptions = (
  historyAction?: "WPP" | "EMAIL" | "EMAIL_LEMBRETE"
): SelectOption[] => {
  const options = [{ text: "Tentar novamente", value: "tentarNovamente" }];

  if (historyAction === "WPP") {
    options.push({ text: "Alterar número de telefone", value: "telefone" });
  }

  if (historyAction === "EMAIL") {
    options.push({ text: "Alterar e-mail", value: "email" });
  }

  if (historyAction === "EMAIL_LEMBRETE") {
    options.push({ text: "Alterar e-mail", value: "email_lembrete" });
  }

  return options;
};
