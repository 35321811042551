import { Typography } from "@material-ui/core";
import cx from "classnames";

interface Props {
  displayDetails: boolean;
  titularTelefone: string;
  titularEmail: string;
  titularDtNascimento: string;
  dataAvp: string;
  localAtendimento: string;
  dtRevogacao: string;
  revogadoPor: string;
  className?: string;
  ceiCaepf?: string;
}

const Details = ({
  displayDetails,
  titularTelefone,
  titularEmail,
  titularDtNascimento,
  dataAvp,
  localAtendimento,
  dtRevogacao,
  revogadoPor,
  className,
  ceiCaepf,
}: Props) => {
  return (
    <div className={cx("flex w100", className)}>
      {displayDetails && (
        <div className="flex flex-column pl8">
          <Typography variant="subtitle2" gutterBottom component="div">
            Titular (Detalhes)
          </Typography>
          <Typography variant="body2" gutterBottom component="div">
            Telefone: {titularTelefone}
          </Typography>
          <Typography variant="body2" gutterBottom component="div">
            Email: {titularEmail}
          </Typography>
          <Typography variant="body2" gutterBottom component="div">
            Data de Nascimento: {titularDtNascimento}
          </Typography>
        </div>
      )}
      <div className={`flex-flex-column ${displayDetails ? "ml72" : "pl8"}`}>
        <Typography variant="subtitle2" gutterBottom component="div">
          Atendimento (AVP)
        </Typography>
        <Typography variant="body2" gutterBottom component="div">
          Data: {dataAvp}
        </Typography>
        <Typography variant="body2" gutterBottom component="div">
          Local: {localAtendimento}
        </Typography>
      </div>
      {(dtRevogacao || revogadoPor) && (
        <div className="flex-flex-column ml72">
          <Typography variant="subtitle2" gutterBottom component="div">
            Revogação
          </Typography>
          <Typography variant="body2" gutterBottom component="div">
            Data: {dtRevogacao}
          </Typography>
          <Typography variant="body2" gutterBottom component="div">
            Revogado Por: {revogadoPor}
          </Typography>
        </div>
      )}
      {ceiCaepf && (
        <div className="flex-flex-column ml72">
          <Typography variant="subtitle2" gutterBottom component="div">
            Outros
          </Typography>
          <Typography variant="body2" gutterBottom component="div">
            CEI/CAEPF: {ceiCaepf}
          </Typography>
        </div>
      )}
    </div>
  );
};

export default Details;
