import React from 'react';
import _get from 'lodash/get';
import { Field as FormikField } from 'formik';

interface Props {
    name?: string;
    wrappedComponent: typeof React.Component
}

const Field = ({ name, wrappedComponent: WappedComponent, ...props }: Props) => (
    <FormikField name={name}>
        {({ field, form }: any) => {
            const error = _get(form, `errors.${name}`, null);
            const touched = _get(form, `touched.${name}`, false);

            return (
                <WappedComponent 
                    {...props}
                    {...field}
                    setFieldValue={form.setFieldValue}
                    error={error}
                    touched={touched}
                />
            );
        }}
    </FormikField>
)

const withField = (WappedComponent: any) => ({ ...props }) => (
    <Field {...props} wrappedComponent={WappedComponent} />
)

export default withField;