import { useMemo } from "react";
import { Grid, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

import { Input } from "../../../../components/Input";
import { Select } from "../../../../components/Select";
import { estados } from "../../../../assets/json/estados";
import SelectOption from "../../../../interfaces/SelectOption";
import { normalizeString } from "../../../../utils/normalizeString";
import { KeyboardDatePicker } from "@material-ui/pickers";

export const empresaStatus: SelectOption[] = [
  { text: "Ativa", value: "ATIVA" },
  { text: "Não Ativa", value: "NÃO ATIVA" },
  { text: "A Processar", value: "A PROCESSAR" },
];

const dtOptions: SelectOption[] = [
  { text: "Abertura da empresa", value: "data_inicio_atividade" },
  { text: "Inserçao no sistema", value: "criado_em" },
];

export const EmpresasFilter = ({ filter, onFilter }: any) => {
  const locationOptions = useMemo(
    () =>
      estados.reduce(
        (acc, estado) => [
          ...acc,
          ...estado.cidades.map((city) => `${city} - ${estado.sigla}`),
        ],
        [] as string[]
      ),
    []
  );

  return (
    <div className="flex-grow-1">
      <Grid container spacing={2}>
        <Grid xs={12} md item>
          <Input
            placeholder="CNPJ"
            value={filter.cnpj}
            onChange={onFilter("cnpj")}
          />
        </Grid>
        <Grid xs={12} md item>
          <Input
            placeholder="Nome"
            value={filter.nome}
            onChange={onFilter("nome")}
          />
        </Grid>
        <Grid xs={12} md item>
          <Input
            placeholder="Número de Telefone"
            value={filter.telefone}
            onChange={onFilter("telefone")}
          />
        </Grid>
        <Grid xs={12} md item>
          <Autocomplete
            autoHighlight
            value={filter.location}
            options={locationOptions}
            onChange={(_, option) =>
              onFilter("localidade")(normalizeString(option))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Localidade"
                inputProps={{
                  style: { height: 38 },
                  ...params.inputProps,
                }}
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid container className="mt8" spacing={2}>
        <Grid xs={12} md item>
          <Select
            label="Data"
            options={dtOptions}
            value={filter.dt}
            onChange={onFilter("dt")}
            name="dt"
          />
        </Grid>
        <Grid xs={12} md item>
          <KeyboardDatePicker
            autoOk
            clearable
            inputVariant="outlined"
            label="Início Período"
            format="DD/MM/yyyy"
            onChange={onFilter("dtInicio")}
            invalidDateMessage={"Data inválida"}
            value={filter.dtInicio ?? null}
            inputProps={{ style: { height: 56 } }}
            fullWidth
          />
        </Grid>
        <Grid xs={12} md item>
          <KeyboardDatePicker
            autoOk
            clearable
            inputVariant="outlined"
            label="Fim Período"
            format="DD/MM/yyyy"
            onChange={onFilter("dtFim")}
            invalidDateMessage={"Data inválida"}
            value={filter.dtFim ?? null}
            inputProps={{ style: { height: 56 } }}
            fullWidth
          />
        </Grid>
        <Grid xs={12} md item>
          <Select
            name="status"
            label="Status"
            options={empresaStatus}
            value={filter.status}
            onChange={onFilter("status")}
          />
        </Grid>
      </Grid>
    </div>
  );
};
