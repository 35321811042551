interface Props {
  color: string;
  textColor?: string;
  label: string | number;
}

const ColorLabel = ({ textColor = "white", label, color }: Props) => {
  const classes = `flex justify-center items-center br10 ${color}`;

  return (
    <div
      className={classes}
      style={{ minWidth: 75, width: "fit-content", padding: "0 1rem" }}
    >
      <p className={`${textColor} b`}>{label}</p>
    </div>
  );
};

export default ColorLabel;
