import { memo, useState } from "react";

import cx from "classnames";
import { makeStyles } from "@material-ui/core/styles";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import AccountCircle from "@material-ui/icons/AccountCircle";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import logoBranca from "../../assets/img/logo-white.png";
import Typography from "@material-ui/core/Typography";
import { useAuth } from "../../hooks/auth";

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: 240,
    width: `calc(100% - ${240}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
}));

interface Props {
  handleDrawer: (event: any) => void;
  open: boolean;
}

const Header = ({ handleDrawer, open }: Props) => {
  const { signOut } = useAuth();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const isOpen = Boolean(anchorEl);
  const handleOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const {
    authData: { user },
  } = useAuth();

  const nome = user.nome.split(" ");
  let userName = "";
  if (nome.length > 1) {
    if (nome[1].length <= 2) {
      userName = user.nome.split(" ", 3).join(" ");
    } else {
      userName = user.nome.split(" ", 2).join(" ");
    }
  } else {
    userName = user.nome;
  }

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <AppBar
      position="fixed"
      className={cx(classes.appBar, {
        [classes.appBarShift]: open,
      })}
      color="primary"
    >
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawer}
          edge="start"
          className={cx(classes.menuButton, {
            [classes.hide]: open,
          })}
        >
          <MenuIcon />
        </IconButton>

        <div className="flex-grow-1">
          <img
            src={logoBranca}
            style={{ width: 120, height: 40 }}
            alt="Logo BrasilCertec"
          />
        </div>
        <div className="flex flex-row items-center">
          <Typography variant="body1" display="block">
            {`Olá, ${userName}`}
          </Typography>
          <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpen}
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={isOpen}
            onClose={handleClose}
          >
            <MenuItem onClick={() => signOut()}>Sair</MenuItem>
          </Menu>
        </div>
      </Toolbar>
    </AppBar>
  );
};

// Header.propTypes = {
//   handleDrawer: PropTypes.func.isRequired,
//   open: PropTypes.bool,
// };

// Header.defaultProps = {
//   open: false,
// };

export default memo(Header);
