interface Props {
  color: string;
  title?: string;
  textColor?: string;
  label: string | number;
}

const CircleLabel = ({ textColor = "white", label, color, title }: Props) => {
  const classes = `flex justify-center items-center br2 ${color}`;

  return (
    <div
      title={title}
      className={classes}
      style={{ minWidth: 34, width: "fit-content", padding: ".5rem" }}
    >
      <p className={`${textColor} b`}>{label}</p>
    </div>
  );
};

export default CircleLabel;
