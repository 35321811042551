const headers = [
  {
    label: "Protocolo",
  },
  {
    label: "Mensagem",
  },
];

const cells = [{ dataObjectKey: "protocolo" }, { dataObjectKey: "msg" }];

const rowKeyProperty = "protocolo";

export { headers, cells, rowKeyProperty };
