import React, { useCallback, useState } from "react";
import HistoryModel from "../../../../interfaces/HistoryModel";
import Button from "../../../../components/Button";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { getCustomElements } from "./HistoryRow.utils";
import ColorLabel from "../../../../components/ColorLabel";
import moment from "moment";
import HistoryActionDialog from "../HistoryActionDialog/HistoryActionDialog";
import HistoryApi from "../../../../resources/history";
import { toast } from "react-toastify";

interface Props {
  history: HistoryModel;
}

const HistoryRow: React.FC<Props> = ({ history }) => {
  const [open, setOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  const { customAction, customCreatedAt, customExpirationDate } =
    getCustomElements(history);

  const {
    protocolo,
    mensagem,
    status,
    logProcessos,
    quantidadeDeTentativas,
    produto,
    titularNome,
  } = history;

  const statusIsError = status === "ERRO";
  const showCollapseButton = statusIsError || !!logProcessos.length;

  const numberOfTries =
    quantidadeDeTentativas > 1
      ? ` em ${quantidadeDeTentativas} tentativas`
      : "";

  const submitActionToTake = useCallback(
    (id: number, actionToTake: string, actionValue?: string | Date) => {
      let parsedValue = actionValue as string;

      if (actionToTake === "telefone") {
        parsedValue = "55" + parsedValue.replace(/[^\d]/g, "");
      }

      HistoryApi.updateTakingAction({
        id,
        actionToTake,
        actionValue: parsedValue,
      })
        .then((response) => {
          if (response.data) {
            history.status = response.data.status;
            history.mensagem = response.data.mensagem;
            history.quantidadeDeTentativas =
              response.data.quantidadeDeTentativas;

            if (response.data.logProcessos.length) {
              history.logProcessos = [
                ...(history.logProcessos || []),
                response.data.logProcessos[0],
              ];
            }
          }
          setDialogOpen(false);
        })
        .catch((error) => {
          setDialogOpen(false);
          toast.error(error.message || error);
        });
    },
    [history]
  );

  return (
    <React.Fragment>
      <TableRow>
        <TableCell component="th" scope="row">
          {protocolo}
        </TableCell>
        <TableCell>{produto}</TableCell>
        <TableCell>{customExpirationDate}</TableCell>
        <TableCell>{titularNome}</TableCell>
        <TableCell>{customAction}</TableCell>
        <TableCell>{mensagem.trim()}</TableCell>
        <TableCell>{customCreatedAt}</TableCell>
        <TableCell>
          <>
            {statusIsError ? (
              <ColorLabel label={`Erro ${numberOfTries}`} color="bg-dark-red" />
            ) : (
              <ColorLabel
                label={`Sucesso ${numberOfTries}`}
                color="bg-dark-green"
              />
            )}
          </>
        </TableCell>
        <TableCell>
          {showCollapseButton && (
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              {!!logProcessos.length && (
                <>
                  <Typography variant="body1" gutterBottom component="div">
                    Ações realizadas
                  </Typography>
                  <Table size="small" aria-label="purchases">
                    <TableHead>
                      <TableRow>
                        <TableCell>Ação</TableCell>
                        <TableCell>Resultado</TableCell>
                        <TableCell>Situação</TableCell>
                        <TableCell>Realizada em</TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {logProcessos.map((log) => {
                        const momentDate = moment(log.criadoEm);
                        const formattedDate = momentDate.format("DD/MM/yyyy");
                        const formattedDateTime = momentDate.format("HH:mm");

                        const isErrorLog = log.status === "ERRO";

                        return (
                          <TableRow key={log.id}>
                            <TableCell>{log.acao}</TableCell>
                            <TableCell>{log.resultado}</TableCell>
                            <TableCell component="th" scope="row">
                              {isErrorLog ? (
                                <ColorLabel label="Erro" color="bg-dark-red" />
                              ) : (
                                <ColorLabel
                                  label="Sucesso"
                                  color="bg-dark-green"
                                />
                              )}
                            </TableCell>
                            <TableCell>
                              {formattedDate} às {formattedDateTime}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </>
              )}
              <div className="w-100 flex justify-end pa16">
                <Button
                  buttonValue="Realizar Ação"
                  onClick={() => setDialogOpen(true)}
                />
              </div>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <HistoryActionDialog
        history={history}
        open={dialogOpen}
        handleSubmit={submitActionToTake}
        handleClose={() => setDialogOpen(false)}
      />
    </React.Fragment>
  );
};

export default HistoryRow;
