import { Route, Redirect, RouteProps } from "react-router-dom";

import { useAuth } from "../../hooks/auth";
import { ProfileOptions } from "../../utils/ProfileOptions";

interface Props extends RouteProps {
  isPrivate?: boolean;
  isGuest?: boolean;
  component: React.FC<any>;
  layout?: React.FC<any>;
  allowedProfiles?: ProfileOptions[];
}

const CustomRoute = ({
  isPrivate = false,
  isGuest = false,
  layout: Layout,
  allowedProfiles,
  ...props
}: Props) => {
  const {
    checkAuth,
    authData: { user },
  } = useAuth();

  const hasUser = checkAuth();
  const mustBeLoggedIn = !hasUser && isPrivate;
  const loggedUserHasAccess =
    !allowedProfiles || allowedProfiles.includes(user?.perfil);

  if (mustBeLoggedIn || !loggedUserHasAccess) {
    return <Redirect to={{ pathname: "/" }} />;
  }

  if (Layout) {
    return (
      <Layout>
        <Route {...props} />
      </Layout>
    );
  }

  return <Route {...props} />;
};

export default CustomRoute;
