import { Link } from 'react-router-dom';

import LeftArrow from '../../assets/svg/arrow-left.svg'

import styles from './style.module.css';

interface Props {
    url: string;
    text: string;
    title?: string;
}

const ArrowBack = ({ url, text, title }: Props) => (
    <div className="flex flex-column" title={title}>
        <Link to={`${url}`} className="flex flex-row no-underline underline-hover color-inherit">
          <img src={LeftArrow} alt="Voltar" className={styles.inputImgStyle}></img>    
          <label className={styles.labelImg}>{ text }</label>
        </Link>
    </div>
);
  
export default ArrowBack;