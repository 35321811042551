import { useCallback, useState } from "react";
import Accordion from "@material-ui/core/Accordion";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";

import styles from "./style.module.css";
import { toast } from "react-toastify";
import CustomTable from "../../../../components/CustomTable";
import { UploadResultSectionProps } from "./UploadResultSection.types";
import { cells, headers, rowKeyProperty } from "./UploadResultSection.utils";

const UploadResultSection: React.FC<UploadResultSectionProps> = ({
  data,
  title,
  legend,
}) => {
  const [expanded, setExpanded] = useState(false);

  const handleCopyClick = useCallback(
    (ev: React.MouseEvent<HTMLElement>) => {
      ev.stopPropagation();

      const protocols = data.map((item) => item.protocolo).join("; ");

      navigator.clipboard.writeText(protocols).then(
        function () {
          toast.info("Protocolos copiados com sucesso! (Ctrl + C)");
        },
        function (err) {
          toast.error(
            `Erro ao copiar protocolos! (Contacte o administrador - ${err})`
          );
        }
      );
    },
    [data]
  );

  return (
    <Accordion
      key={title}
      expanded={expanded}
      onChange={() => setExpanded((prevState) => !prevState)}
    >
      <AccordionSummary
        id={title}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1bh-content"
      >
        <strong>{`${title} (${data.length})`}</strong>
        <span className={styles.legend}>{legend}</span>
        <button
          type="button"
          onClick={handleCopyClick}
          title="Copiar protocolos"
          className={styles.copyIcon}
        >
          <FileCopyIcon />
        </button>
      </AccordionSummary>
      <AccordionDetails className={styles.tableWrapper}>
        <CustomTable
          data={data}
          cells={cells}
          headers={headers}
          rowKeyProperty={rowKeyProperty}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default UploadResultSection;
