import { useCallback, useState, useMemo } from "react";
import { toast } from "react-toastify";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";

import Emissao from "../../resources/emissao";
import { UploadForm } from "./components/UploadForm";
import { UploadEmissionData } from "../../interfaces/UploadEmissionData";
import {
  EmissionResults,
  UploadCsvResult,
} from "../../interfaces/UploadCsvResult";
import UploadResultContent from "./components/UploadResultContent/UploadResultContent";

const initialUploadState = {
  origin: "serpro",
} as UploadEmissionData;

const Upload = () => {
  const [loading, setLoading] = useState(false);
  const [uploadResult, setUploadResult] = useState<EmissionResults[]>([]);
  const [totalOfEmissionsUploaded, setTtotalOfEmissionsUploaded] = useState(0);
  const [uploadState, setUploadState] =
    useState<UploadEmissionData>(initialUploadState);

  const submitForm = useCallback(async (data: UploadEmissionData) => {
    setLoading(true);
    setUploadResult([]);
    setTtotalOfEmissionsUploaded(0);

    for (const file of data.files) {
      try {
        const res = await Emissao.upload(file, data.origin);
        if (res.data) {
          setTtotalOfEmissionsUploaded(
            (prevState) => prevState + res.data.length
          );

          setUploadResult((prevState) => [
            ...prevState,
            {
              fileName: file.name,
              results: res.data as UploadCsvResult[],
            },
          ]);
        }
      } catch (err: any) {
        let message = "";
        if (err?.data?.message) {
          message = err.data.message;
        } else {
          message = err?.message || err;
        }

        if (message) {
          toast.error(`${message} (${file.name})`);
        }
      }
    }

    setLoading(false);
  }, []);

  const showContent = useMemo(
    () => Boolean(uploadResult.length),
    [uploadResult]
  );

  return (
    <div className="pa8 pa24-l">
      <Card className="pa16">
        <div className="flex mb8">
          <div
            className="flex justify-center items-center"
            style={{ height: 36 }}
          >
            <h2>Upload de Planilhas</h2>
          </div>
        </div>
        <div className="flex">
          <UploadForm
            loading={loading}
            submitForm={submitForm}
            uploadState={uploadState}
            setUploadState={setUploadState}
          />
        </div>
      </Card>
      {showContent && (
        <Card className="pa16  mt24">
          <Typography variant="overline" className="mb16">
            Foram processadas {totalOfEmissionsUploaded} emissoes em{" "}
            {uploadResult.length} arquivo(s)
          </Typography>
          <div className="flex flex-column">
            {uploadResult.map((result) => (
              <UploadResultContent key={result.fileName} result={result} />
            ))}
          </div>
        </Card>
      )}
    </div>
  );
};

export default Upload;
