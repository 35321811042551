import {
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
} from "@material-ui/core";
import { ITHeadProps } from "./types";

const THead = <T extends object>({
  headers,
  orderDirection,
  orderBy,
  handleSort,
  actions,
}: ITHeadProps<T>) => {
  const tableDefaultHeaders = headers.map(({ label, orderByLabel, align }) => (
    <TableCell
      key={orderByLabel || label}
      align={align ?? "left"}
      sortDirection={orderDirection}
    >
      {orderByLabel ? (
        <Tooltip title="Ordenar" enterDelay={300}>
          <TableSortLabel
            active={orderBy === orderByLabel}
            direction={!orderDirection ? undefined : orderDirection}
            onClick={() => {
              if (handleSort) {
                handleSort(orderByLabel);
              }
            }}
          >
            {label}
          </TableSortLabel>
        </Tooltip>
      ) : (
        <span>{label}</span>
      )}
    </TableCell>
  ));

  const tableHeaderActions = actions && (
    <TableCell key="actions" align="center" />
  );

  const tableHeaders = tableHeaderActions
    ? [...tableDefaultHeaders, tableHeaderActions]
    : tableDefaultHeaders;

  return (
    <TableHead>
      <TableRow>{tableHeaders}</TableRow>
    </TableHead>
  );
};

export default THead;
