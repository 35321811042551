import { useEffect, useState } from "react";
import * as yup from "yup";
import { Formik } from "formik";

import addToast from "../../utils/toast";
import Token from "../../resources/token";
import useQuery from "../../utils/useQuery";
import logo from "../../assets/img/main-logo.png";
import ChangePasswordForm from "./ChangePasswordForm";

interface FormData {
  password?: string;
  confirmPassword?: string;
  email?: string;
}

const ChangePassword = () => {
  const [token, setToken] = useState("");
  const [userName, setUserName] = useState("");

  const query = useQuery();
  const t = query.get("t");

  useEffect(() => {
    if (t && t !== "") {
      setToken(t);

      Token.verifyToken(t)
        .then(({ data }) => {
          const [firstName, secondName] = data.userName.split(" ");

          setUserName(firstName + (secondName ? ` ${secondName}` : ""));
        })
        .catch(() => {
          addToast("Este link não é válido", { type: "error" });
        });
    }
  }, [t]);

  const validationSchemaChangePassword = yup.object().shape({
    password: yup
      .string()
      .required("Senha é um campo obrigatório!")
      .min(6, "A Senha deve ter no mínimo 6 caracteres!")
      .test(
        "consistPasswordHasAtLeastOneNumber",
        "Informe pelo menos um número!",
        (value?: string) => {
          if (!value) return false;

          return /\d/.test(value);
        }
      )
      .test(
        "consistPasswordHasAtLeastOneLetter",
        "Informe pelo menos uma letra!",
        (value?: string) => {
          if (!value) return false;

          return /[a-zA-Z]/.test(value);
        }
      ),
    confirmPassword: yup
      .string()
      .required("Confirmar Senha é um campo obrigatório!")
      .oneOf([yup.ref("password"), null], "As senhas não correspondem!"),
  });

  const validationSchemaRecovery = yup.object().shape({
    email: yup
      .string()
      .email("E-mail inválido")
      .required("E-mail é um campo obrigatório"),
  });

  const onSubmit = (values: FormData) => {
    if (values.email) {
      Token.sendRecoveryEmail(token, values.email)
        .then(({ data }) => {
          if (data.message) {
            addToast(data.message, { type: "success" });
          }
        })
        .catch((error) => {
          const { data } = error;
          if (data && data.message) {
            addToast(data.message, { type: "error" });
          } else if (error.message) {
            addToast(error.message, { type: "error" });
          }
        });
    } else if (values.password && values.confirmPassword) {
      Token.updatePassword(token, values.password)
        .then((response) => {
          const { data } = response;
          if (data.message) {
            addToast(data.message, { type: "success" });
          }
        })
        .catch((error) => {
          const { data } = error;
          if (data && data.message) {
            addToast(data.message, { type: "error" });
          } else if (error.message) {
            addToast(error.message, { type: "error" });
          }
        });
    }
  };

  return (
    <div>
      <div className="w-100 vh-100 flex justify-center items-center">
        <div className="w-100 w-40-m" style={{ maxWidth: "25rem" }}>
          <div className="flex flex-column justify-center items-center bg-zircon">
            <img src={logo} alt="Logo BrasilCertec" />
            {Boolean(userName) ? (
              <div className="w-100 flex flex-column flex-start pa12 ma4">
                <h3>Olá, {userName}.</h3>
                <p className="fz12 mt8">
                  Crie agora sua nova senha. <b>Regras:</b>
                </p>
                <ul className="mt16 ml16">
                  <li>Utilize no mínimo 6 caracteres.</li>
                  <li>Informe pelo menos uma letra.</li>
                  <li>Informe pelo menos um número.</li>
                </ul>
              </div>
            ) : (
              <div className="w-100 flex flex-column flex-start pa12 ma4">
                <h3>Recuperação de Senha</h3>
                <p className="fz12 mt8">
                  Informe seu email utilizado no sistema para recuperar sua
                  senha.
                </p>
              </div>
            )}
            <Formik
              initialValues={{
                password: "",
                confirmPassword: "",
                email: "",
              }}
              enableReinitialize
              validationSchema={
                Boolean(userName)
                  ? validationSchemaChangePassword
                  : validationSchemaRecovery
              }
              onSubmit={onSubmit}
            >
              {({ values, errors, handleSubmit, handleChange, handleBlur }) => (
                <ChangePasswordForm
                  userName={userName}
                  values={values}
                  errors={errors}
                  handleSubmit={handleSubmit}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
