import { makeStyles } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import React, { useState } from 'react';
import EmissionGrid from '../../../../interfaces/EmissionGrid';
import Details from '../Details';
import cx from 'classnames';

import styles from './style.module.css';
import ColorLabel from '../../../ColorLabel';

interface Props {
    row: EmissionGrid, 
    displayDetails: boolean,
    disableTextSelection: boolean,
}

const CustomRow = ({
    row,
    displayDetails,
    disableTextSelection = false,
}: Props) => {
    const [open, setOpen] = useState(false);

    const useRowStyles = makeStyles({
        root: {
          '& > *': {
            borderBottom: 'unset',
          },
        },
    });
    const classes = useRowStyles();

    return (
      <React.Fragment>
        <TableRow className={cx(classes.root, disableTextSelection ? styles.disableTextSelection: null)}>
          <TableCell>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell className="pa8" component="th" scope="row">{row.protocolo}</TableCell>
          <TableCell className="pa8">{row.nome}</TableCell>
          <TableCell className="pa8">{row.documento}</TableCell>
          <TableCell className="pa8">{row.titularNome}</TableCell>
          <TableCell className="pa8">{row.titularCpf}</TableCell>
          <TableCell className="pa8">{row.produto}</TableCell>
          <TableCell className="pa8">{row.avpNome}</TableCell>
          <TableCell className="pa8">{row.avpCpf}</TableCell>
          <TableCell className="pa8" align="center">{row.dtInicioValidade}</TableCell>
          <TableCell className="pa8" align="center">{row.dtFimValidade}</TableCell>
          <TableCell className="pa8" align="center">
              {row.status === "E" && (
                  <ColorLabel
                      label="Emitido"
                      color="bg-dark-green"
                  />
              )}
              {row.status === "R" && (
                  <ColorLabel
                      label="Revogado"
                      color="bg-dark-red"
                  />
              )}
              {row.status === "C" && (
                  <ColorLabel
                      label="Cancelado"
                      color="bg-dark-gray"
                  />
              )}
              {row.status === "P" && (
                  <ColorLabel
                      textColor="black"
                      label="Pendente"
                      color="bg-gold"
                  />
              )}
          </TableCell>
          <TableCell className="pa8">
            {row.situacao && (<ColorLabel
              label={row.situacao === "A" ? "Ativo" : "Vencido"}
              color={row.situacao === "A" ? "bg-dark-green" : "bg-dark-gray"}/>
            )}
          </TableCell>
        </TableRow>
        <TableRow className="bg-near-white">
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={13}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <TableRow className={classes.root}>
                <TableCell />
                <TableCell>
                  <Details
                    className={cx(disableTextSelection ? styles.disableTextSelection: null)}
                    displayDetails={displayDetails}
                    titularTelefone={row.titularTelefone}
                    titularEmail={row.titularEmail}
                    titularDtNascimento={row.titularDtNascimento}
                    dataAvp={row.dtAvp}
                    localAtendimento={row.localAtendimento}
                    dtRevogacao={row.dtRevogacao}
                    revogadoPor={row.revogadoPor}
                    ceiCaepf={row.ceiCaepf}
                  />
                </TableCell>
              </TableRow>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
}

export default CustomRow;