import { Moment } from "moment";

import { normalizeString } from "../../../../utils/normalizeString";

export const getRequestConfig = (
  start: Moment,
  end: Moment,
  location: string,
  page: number
): any => {
  const formatedStartDate = start.format("YYYY-MM-DD");
  const formatedEndDate = end.format("YYYY-MM-DD");

  const [city, uf] = location ? normalizeString(location).split(" - ") : [];

  const parsedUf = uf ? [uf.toLocaleUpperCase()] : [];
  const parsedCity = city ? [city.toLocaleUpperCase()] : [];

  return {
    method: "POST",
    url: "https://api.casadosdados.com.br/v2/public/cnpj/search",
    headers: {
      "Content-Type": "application/json",
      "User-Agent": "Axios 1.4.0",
    },
    data: {
      query: {
        termo: [],
        atividade_principal: [],
        natureza_juridica: [],
        uf: parsedUf,
        municipio: parsedCity,
        bairro: [],
        situacao_cadastral: "ATIVA",
        cep: [],
        ddd: [],
      },
      range_query: {
        data_abertura: { lte: formatedEndDate, gte: formatedStartDate },
        capital_social: { lte: null, gte: null },
      },
      extras: {
        somente_mei: false,
        excluir_mei: true,
        com_email: true,
        incluir_atividade_secundaria: false,
        com_contato_telefonico: true,
        somente_fixo: false,
        somente_celular: false,
        somente_matriz: true,
        somente_filial: false,
      },
      page,
    },
  };
};
