import { AxiosInstance } from "axios";
import _isEmpty from "lodash/isEmpty";
import { useEffect } from "react";
import { createContext, useCallback, useContext, useState } from "react";
import { Redirect } from "react-router-dom";
import doLogin from "../pages/Login/requests";
import api from "../resources/api";
import addToast from "../utils/toast";
import { ProfileOptions } from "../utils/ProfileOptions";

interface Props {
  children: JSX.Element;
}

interface IAuthData {
  user: {
    id: string;
    nome: string;
    perfil: ProfileOptions;
  };
  token: string;
}

interface IAuthContext {
  api: AxiosInstance;
  signIn: (formData: FormData, history: any) => void;
  signOut: (redirect?: boolean) => void;
  checkAuth: () => boolean;
  authData: IAuthData;
}

interface FormData {
  email: string;
  password: string;
}

const AuthContext = createContext<IAuthContext>({} as IAuthContext);

export const AuthProvider = ({ children }: Props) => {
  const [authData, setAuthData] = useState<IAuthData>(() => {
    const token = localStorage.getItem("@Auth/access_token");
    const user = JSON.parse(localStorage.getItem("user") ?? "{}");

    if (token) {
      return {
        token,
        user,
      } as IAuthData;
    }

    return {
      user: null as any,
      token: null as any,
    } as IAuthData;
  });

  const signIn = useCallback(({ email, password }: FormData, history: any) => {
    doLogin(email, password)
      .then((response) => {
        const { data } = response;

        if (data) {
          const { token, user } = data;
          if (token && user) {
            localStorage.setItem("@Auth/access_token", `Bearer ${data.token}`);
            localStorage.setItem("user", JSON.stringify(data.user));

            setAuthData({
              token: data.token,
              user: data.user,
            });

            history.push("/home");
          } else {
            addToast("Erro inesperado! Entre em contato com o", {
              type: "error",
            });
          }
        } else {
          addToast("Erro inesperado! Tente novamente mais tarde.", {
            type: "error",
          });
        }
      })
      .catch((error) => {
        const { data } = error;
        if (data) {
          addToast(data.message, { type: "error" });
        } else {
          addToast(error, { type: "error" });
        }
      });
  }, []);

  const checkAuth = useCallback(() => Boolean(authData.token), [authData]);

  const signOut = useCallback((redirect = true) => {
    localStorage.removeItem("@Auth/access_token");
    localStorage.removeItem("user");

    setAuthData({
      token: null as any,
      user: null as any,
    });

    if (redirect) {
      return <Redirect to="/" />;
    }
  }, []);

  useEffect(() => {
    api.interceptors.response.use(undefined, ({ response }) => {
      if (response && response.data) {
        const { data } = response;

        if (response.status === 401 && data.message === "Invalid Token.") {
          addToast("Sua sessão expirou!", { type: "error" });
          return signOut();
        }
      }

      return Promise.reject(response);
    });
  }, [signOut]);

  return (
    <AuthContext.Provider
      value={{
        api,
        authData,
        signIn,
        signOut,
        checkAuth,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  const context = useContext(AuthContext);
  if (_isEmpty(context)) {
    throw new Error("Component must be inside AuthContext");
  }
  return context;
}
