import api from './api';

const baseURL = '/auth';

const Session = {
    doLogin: (email: string, password: string) => api.post(baseURL, {
        email,
        senha: password 
    })
}

export default Session;
