import HouseIcon from "@material-ui/icons/House";
import PersonIcon from "@material-ui/icons/Person";
import SettingsIcon from "@material-ui/icons/Settings";
import TimelineIcon from "@material-ui/icons/Timeline";
import BusinessIcon from "@material-ui/icons/Business";
import CloudUpload from "@material-ui/icons/CloudUpload";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";

import MenuItem from "../../interfaces/MenuItem";
import { ProfileOptions } from "../../utils/ProfileOptions";

export const options: MenuItem[] = [
  {
    title: "Home",
    image: <HouseIcon />,
    route: "/home",
  },
  {
    title: "Emissões",
    image: <TimelineIcon />,
    route: "/emissions",
    permissions: [
      ProfileOptions.ADMIN,
      ProfileOptions.GERENTE,
      ProfileOptions.PADRAO,
    ],
  },
  {
    title: "Usuários",
    image: <PersonIcon />,
    route: "/user",
    permissions: [ProfileOptions.ADMIN, ProfileOptions.GERENTE],
  },
  {
    title: "Upload",
    image: <CloudUpload />,
    route: "/upload",
    permissions: [ProfileOptions.ADMIN],
  },
  {
    title: "Emails/Wpp",
    image: <NotificationsActiveIcon />,
    route: "/history",
    permissions: [ProfileOptions.ADMIN, ProfileOptions.GERENTE],
  },
  {
    title: "Empresas",
    image: <BusinessIcon />,
    route: "/business",
    permissions: [
      ProfileOptions.ADMIN,
      ProfileOptions.SUPERVISOR,
      ProfileOptions.VENDAS,
    ],
  },
  {
    title: "Configurações",
    image: <SettingsIcon />,
    route: "/config",
    permissions: [ProfileOptions.ADMIN],
  },
];
