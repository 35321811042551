import api from './api';

const baseUrl = '/token';

const Token = {
    verifyToken: (token: string | null) => api.get(`${baseUrl}?t=${token}`),
    sendRecoveryEmail: (token: string, email: string) => api.post(`${baseUrl}`, {
        token, email
    }),
    updatePassword: (token: string, password: string) => api.put(`${baseUrl}`, {
        token,
        senha: password
    })
}

export default Token;