import api from "./api";
import FilterEmission from "../interfaces/FilterEmission";
import { UploadCsvResult } from "../interfaces/UploadCsvResult";

const baseUrl = "/emissao";

const Emissao = {
  getAll: (filter: FilterEmission, page: number, perPage: number) => {
    return api.get(baseUrl, {
      params: {
        ...filter,
        page,
        perPage,
        documento: filter.documento
          ? filter.documento.replace(/[^0-9]/g, "")
          : undefined,
        avpCpf: filter.avpCpf
          ? filter.avpCpf.replace(/[^0-9]/g, "")
          : undefined,
        titularCpf: filter.titularCpf
          ? filter.titularCpf.replace(/[^0-9]/g, "")
          : undefined,
        dtInicio: filter.dtInicio
          ? filter.dtInicio.format("yyyy-MM-DD")
          : undefined,
        dtFim: filter.dtFim ? filter.dtFim.format("yyyy-MM-DD") : undefined,
      },
    });
  },
  upload: (file: File, origin: string) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("origin", origin);

    return api.post<UploadCsvResult[]>(`${baseUrl}/import`, formData, {
      headers: {
        "Content-type": "multipart/form-data",
      },
    });
  },
};

export default Emissao;
