import FilterHistory from "../interfaces/FilterHistory";
import api from "./api";

const baseUrl = "/history";

const History = {
  getAll: (filter: FilterHistory, page: number, perPage: number) => {
    return api.get(baseUrl, {
      params: {
        ...filter,
        page,
        perPage,
        startDate: filter.startDate
          ? filter.startDate.format("yyyy-MM-DD")
          : undefined,
        endDate: filter.endDate
          ? filter.endDate.format("yyyy-MM-DD")
          : undefined,
      },
    });
  },
  updateTakingAction: ({
    id,
    actionToTake,
    actionValue,
  }: {
    id: number;
    actionToTake: string;
    actionValue?: string | Date;
  }) => {
    return api.put(`${baseUrl}/${id}`, {
      [actionToTake]: actionValue,
    });
  },
};

export default History;
