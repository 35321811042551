import api from "./api";

const baseUrl = "/config";

const Config = {
  getAll: async () => {
    return api.get(baseUrl);
  },
  update: async (id: number, body: { label: string; value: string }) => {
    return api.put(`${baseUrl}/${id}`, body);
  },
  updateByLabel: async ({ label, value }: { label: string; value: string }) => {
    return api.put(`${baseUrl}/by-label/${label}`, { value });
  },
};

export default Config;
