import { useState } from "react";
import ArrowBack from "../../../components/ArrowBack";
import Button from "../../../components/Button";
import Input from "../../../components/Input";

interface Props {
  userName?: string;
  values: any;
  errors: any;
  handleSubmit: (e?: React.FormEvent<HTMLFormElement>) => void;
  handleChange: (e: React.ChangeEvent<any>) => void;
  handleBlur: (e: React.FocusEvent<any>) => void;
}

const ChangePasswordForm = ({
  userName,
  values,
  errors,
  handleSubmit,
  handleChange,
  handleBlur,
}: Props) => {
  const [password, setPassword] = useState("");

  const handlePasswordChange = (value: string) => {
    setPassword(value);
  };

  return (
    <form className="w-100 pa12 ma4" onSubmit={handleSubmit}>
      {Boolean(userName) ? (
        <>
          <div className="mb16 w-100">
            <Input
              name="password"
              type="password"
              placeholder="Senha"
              label="Senha"
              mask={null}
              error={errors.password}
              onChange={() => handlePasswordChange(values.password)}
              onBlur={handleBlur}
              value={password}
              variant="outlined"
            />
          </div>
          <div className="mb24 w-100">
            <Input
              name="confirmPassword"
              type="password"
              placeholder="Confirmar Senha"
              label="Confirmar Senha"
              mask={null}
              error={errors.confirmPassword}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.confirmPassword}
              variant="outlined"
            />
          </div>
        </>
      ) : (
        <div className="mb12 w-100">
          <Input
            name="email"
            variant="outlined"
            inputType="text"
            labelName="Email"
            placeholder="Email"
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.email}
            required
          />
        </div>
      )}
      <div className="flex justify-between items-center">
        <ArrowBack
          url="/"
          text="Voltar para Login"
          title="Voltar para tela de Login"
        />
        <Button type="submit" buttonValue="ENVIAR" />
      </div>
    </form>
  );
};

export default ChangePasswordForm;
