import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Card } from "@material-ui/core";
import Switch from "@material-ui/core/Switch";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import ConfigApi from "../../resources/config";
import { ConfigModel } from "../../interfaces/ConfigModel";

const parsedLabels = {
  isEmailNotificationActive:
    "Envio automático de e-mails de expiração de emissão",
  isWppNotificationActive:
    "Envio automático de whatsapp de expiração de emissão",
  isInstalationReminderActive:
    "Envio automático de e-mail de lembrete de instalação de certificado",
  isBusinessUpdaterActive:
    "Atualizador automático de dados cadastrais das empresas",
} as { [key: string]: string };

const Config: React.FC = () => {
  const [configs, setConfigs] = useState([] as ConfigModel[]);

  const getConfigs = useCallback(() => {
    ConfigApi.getAll()
      .then((response) => {
        setConfigs(response?.data || []);
      })
      .catch((error) => toast.error(error?.message || error));
  }, []);

  const switchConfig = useCallback(
    (id: number, label: string, value: boolean) => {
      ConfigApi.update(id, { label, value: `${value}` })
        .then(() => {
          getConfigs();
        })
        .catch((error) => toast.error(error.message));
    },
    [getConfigs]
  );

  useEffect(() => {
    getConfigs();
  }, []);

  return (
    <div className="pa8 pa24-l">
      <Card className="pa16">
        <div className="flex justify-between">
          <div
            className="flex justify-center items-center"
            style={{ height: 36 }}
          >
            <h2>Configurações do sistema</h2>
          </div>
        </div>
      </Card>

      <Card className="pa16 mt24">
        <FormGroup>
          {configs.map(({ id, label, value }) => {
            const isChecked = value === "true";

            return (
              <FormControlLabel
                key={id}
                label={parsedLabels[label]}
                control={
                  <Switch
                    color="primary"
                    checked={isChecked}
                    onChange={() => switchConfig(id, label, !isChecked)}
                  />
                }
              />
            );
          })}
        </FormGroup>
      </Card>
    </div>
  );
};

export default Config;
