import { useCallback, useEffect, useMemo, useState } from "react";
import axios from "axios";
import moment, { Moment } from "moment";
import { Autocomplete } from "@material-ui/lab";
import { KeyboardDatePicker } from "@material-ui/pickers";
import {
  Grid,
  Dialog,
  TextField,
  DialogTitle,
  DialogContent,
  DialogContentText,
  CircularProgress,
} from "@material-ui/core";

import addToast from "../../../../utils/toast";
import Config from "../../../../resources/config";
import Button from "../../../../components/Button";
import Empresa from "../../../../resources/empresas";
import { estados } from "../../../../assets/json/estados";
import { ConfigLabels } from "../../../../utils/ConfigLabels";
import { getRequestConfig } from "./CreateEmpresasDialog.utils";
import {
  PayloadObject,
  ResponseObject,
  CreateEmpresasDialogProps,
} from "./CreateEmpresasDialog.types";

export const CreateEmpresasDialog: React.FC<CreateEmpresasDialogProps> = ({
  setDialogOpen,
  executeAfterFinish,
}) => {
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);

  const [page, setPage] = useState(1);
  const [end, setEnd] = useState<Moment | null>(moment());
  const [start, setStart] = useState<Moment | null>(moment());
  const [location, setLocation] = useState<string | null>("Uberlândia - MG");

  const [totalToProcess, setTotalToProcess] = useState(0);
  const [empresasToProcess, setEmpresasToProcess] = useState(
    [] as PayloadObject[]
  );

  const locationOptions = useMemo(
    () =>
      estados.reduce(
        (acc, estado) => [
          ...acc,
          ...estado.cidades.map((city) => `${city} - ${estado.sigla}`),
        ],
        [] as string[]
      ),
    []
  );

  const [startErrorMsg, endErrorMsg] = useMemo(() => {
    let startError = "";
    let endError = "";

    if (!start) {
      startError = "Campo obrigatório!";
    }

    if (start && start.isAfter(end)) {
      startError = "Não pode ser maior que a Data de Fim";
    }

    if (!end) {
      endError = "Campo obrigatório!";
    }

    if (end && end.isBefore(end)) {
      endError = "Não pode ser menor que a Data de Início";
    }

    return [startError, endError];
  }, [start, end]);

  const lastPage = useMemo(
    () => Math.ceil(totalToProcess / 20),
    [totalToProcess]
  );

  const handleModalClose = useCallback(
    (_, reason: string) => {
      if (uploading && ["backdropClick", "escapeKeyDown"].includes(reason)) {
        return;
      }

      setDialogOpen(false);
    },
    [uploading, setDialogOpen]
  );

  const getEmpresas = useCallback(
    (page: number) => {
      if (start && end && page) {
        axios
          .request<ResponseObject>(
            getRequestConfig(
              start as Moment,
              end as Moment,
              location || "",
              page
            )
          )
          .then((response) => {
            const res = response.data;

            if (res?.data?.count) {
              setTotalToProcess(res.data.count);
              const payload: PayloadObject[] = res.data.cnpj.map((item) => ({
                cnpj: item.cnpj,
                razaoSocial: item.razao_social,
                nomeFantasia: item.nome_fantasia,
                atividadePrincipal: item.atividade_principal?.descricao,
                openedAt: item.data_abertura,
                cidade: item.municipio,
                uf: item.uf.toUpperCase(),
              }));

              setEmpresasToProcess(payload);
            } else if (page === 1) {
              setLoading(false);
              addToast(
                "Nenhuma empresa encontrada. Tente utilizar outros filtros!",
                { type: "error" }
              );
            }
          })
          .catch((e) => {
            setLoading(false);

            addToast(
              e?.response?.data?.message ||
                e?.error ||
                "Aconteceu algum problema ao buscar empresas na api externa!",
              { type: "error" }
            );
          });
      }
    },
    [start, end, location]
  );

  const sendEmpresasToServer = async (empresas: PayloadObject[]) => {
    const response = await Empresa.createEmpresas(empresas);

    return response.data;
  };

  const processingNumber = useMemo(() => {
    if (totalToProcess) {
      const processing = page * 20;
      return Math.min(processing, totalToProcess);
    }
  }, [page, totalToProcess]);

  useEffect(() => {
    if (uploading && empresasToProcess.length) {
      sendEmpresasToServer(empresasToProcess)
        .then((res) => {
          setPage((prevState) => prevState + 1);
        })
        .catch((e) => {
          console.log({ e });
          setLoading(false);
          setUploading(false);

          addToast(
            e?.data?.message ||
              "Não foi possível enviar os dados ao servidor, tente novamente mais tarde!",
            { type: "error" }
          );
        });
    }

    setLoading(false);
  }, [uploading, empresasToProcess]);

  useEffect(() => {
    if (page <= lastPage) {
      getEmpresas(page);
    } else if (page > 1) {
      Config.updateByLabel({
        value: "true",
        label: ConfigLabels.BUSINESS_UPDATER_SCHEDULER,
      })
        .then(() => {
          addToast(
            `Pronto! Todos os ${totalToProcess} registros foram integrados com sucesso!`,
            { type: "success" }
          );
        })
        .catch((e) => {
          addToast(
            e?.data?.message ||
              e?.error ||
              e?.message ||
              `Não foi possível iniciar o atualizar automático de empresas.\n
            Tente ativá-lo manualmente na página de "Configurações"!`,
            { type: "error" }
          );
        })
        .finally(() => {
          executeAfterFinish();
          setDialogOpen(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  return (
    <Dialog open onClose={handleModalClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Cadastrar novas empresas</DialogTitle>

      <DialogContent>
        {loading ? (
          <Grid item xs={12}>
            <div className="h4 flex flex-column justify-center items-center">
              <CircularProgress />
            </div>
          </Grid>
        ) : totalToProcess ? (
          uploading ? (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div className="mt16 mb16 w6 flex flex-column justify-center items-center">
                  <CircularProgress />
                </div>
              </Grid>
              <Grid item xs={12}>
                <h3 className="mt32 fz24 fw4 tc">
                  Processando {processingNumber} de {totalToProcess}{" "}
                  registros...
                </h3>
              </Grid>
              <Grid item xs={12}>
                <h4 className="mb24 fw4 tc">
                  Aguarde o processo finalizar antes de fechar o modal ou sair
                  do sistema!
                </h4>
              </Grid>
              <Grid item xs={12}>
                <div className="pl24">
                  <h4 className="mb8">Filtros selecionados:</h4>
                  <ul className="pl32 mb32">
                    <li className="pa3">
                      <b>Localização</b>: {location}
                    </li>
                    <li className="pa3">
                      <b>Período</b>: {start?.format("DD/MM/YYYY")} à{" "}
                      {end?.format("DD/MM/YYYY")}
                    </li>
                  </ul>
                </div>
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={2} justifyContent="flex-end">
              <Grid item xs={12}>
                <h3 className="mt32 fz24 fw4 tc">
                  Foram encontradas {totalToProcess} empresas.
                </h3>
              </Grid>
              <Grid item xs={12}>
                <h4 className="mb32 fw4 tc">Deseja salvar todas elas?</h4>
              </Grid>

              <Grid item>
                <Button
                  variant="outlined"
                  buttonValue="Voltar"
                  onClick={() => {
                    setTotalToProcess(0);
                    setEmpresasToProcess([]);
                  }}
                />
              </Grid>

              <Grid item>
                <Button
                  variant="contained"
                  buttonValue="Salvar empresas"
                  onClick={() => setUploading(true)}
                />
              </Grid>
            </Grid>
          )
        ) : (
          <Grid container spacing={2} justifyContent="flex-end">
            <Grid item xs={12}>
              <DialogContentText>
                As empresas que foram abertas na localização e dentro do período
                selecionado abaixo serão cadastradas no sistema
              </DialogContentText>
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                autoHighlight
                value={location}
                options={locationOptions}
                onChange={(_, option) => setLocation(option)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Localidade"
                    inputProps={{
                      style: { height: 38 },
                      ...params.inputProps,
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <KeyboardDatePicker
                autoOk
                clearable
                fullWidth
                value={start}
                format="DD/MM/yyyy"
                label="Data de Início"
                inputVariant="outlined"
                helperText={startErrorMsg}
                invalidDateMessage="Data inválida"
                inputProps={{ style: { height: 56 } }}
                onChange={(value) => setStart(value as Moment)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <KeyboardDatePicker
                autoOk
                clearable
                fullWidth
                value={end}
                format="DD/MM/yyyy"
                label="Data de Fim"
                helperText={endErrorMsg}
                inputVariant="outlined"
                invalidDateMessage="Data inválida"
                inputProps={{ style: { height: 56 } }}
                onChange={(value) => setEnd(value as Moment)}
              />
            </Grid>

            <Grid item>
              <Button
                variant="outlined"
                buttonValue="Cancelar"
                onClick={() => setDialogOpen(false)}
              />
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                buttonValue="Enviar"
                onClick={() => {
                  setLoading(true);
                  getEmpresas(1);
                }}
                disabled={Boolean(endErrorMsg || startErrorMsg)}
              />
            </Grid>
          </Grid>
        )}
      </DialogContent>
    </Dialog>
  );
};
