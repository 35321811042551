import moment from "moment";
import HistoryModel from "../../../../interfaces/HistoryModel";

const formatedActions = {
  WPP: "Whatsapp",
  EMAIL: "E-mail",
  EMAIL_LEMBRETE: "E-mail de lembrete de instalação",
};

export const getCustomElements = ({
  acao,
  diasDeExpiracao,
  criadoEm,
  dtFimValidade,
}: HistoryModel) => {
  const parsedAction = formatedActions[acao] || acao;

  const momentDate = moment(criadoEm);
  const formattedDate = momentDate.format("DD/MM/yyyy");
  const formattedDateTime = momentDate.format("HH:mm");

  const expirationMomentDate = moment(dtFimValidade);
  const expirationFormattedDate = expirationMomentDate.format("DD/MM/yyyy");

  return {
    customAction: `${parsedAction} de ${diasDeExpiracao} dias`,
    customCreatedAt: `${formattedDate} às ${formattedDateTime}`,
    customExpirationDate: `${expirationFormattedDate}`,
  };
};
