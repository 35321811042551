import { cnpj, cpf } from "cpf-cnpj-validator";

const formatCpfCnpj = (value: string) => {
  if (value.length > 11) {
    return cnpj.format(value);
  } else {
    return cpf.format(value);
  }
};

export default formatCpfCnpj;
