import MuiButton, { ButtonProps } from "@material-ui/core/Button";

interface Props extends ButtonProps {
  buttonValue?: React.ReactNode;
}

const Button = ({ buttonValue, ...props }: Props) => (
  <>
    <MuiButton
      style={{ minWidth: "82.63px" }}
      color="primary"
      variant="contained"
      {...props}
    >
      {buttonValue}
    </MuiButton>
  </>
);

export default Button;
