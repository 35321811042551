import { cnpj, cpf } from "cpf-cnpj-validator"

const validateDocument = (doc: string) => {
    return doc.length > 11 ? cnpj.isValid(doc) : cpf.isValid(doc);
};

export default validateDocument;

    // const striped = doc.replace(/[^0-9]/g, "");

    // if(striped.length > 11) {
    //     return cnpj.isValid(striped)
    // }
    // else {
    //     return cpf.isValid(striped);
    // }