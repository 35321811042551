import SelectOption from "../../../../interfaces/SelectOption";

export const originOptions: SelectOption[] = [
  { text: "Serpro", value: "serpro" },
];

export const originColumnNames = {
  serpro: [
    "AR",
    "AC",
    "NUMERO_REFERENCIA*",
    "TIPO_DO_CERTIFICADO*",
    "VALIDADE*",
    "DATA_DE_SOLICITACAO",
    "CPF_DO_SOLICITANTE*",
    "NOME_DO_SOLICITANTE*",
    "EMAIL_DO_SOLICITANTE*",
    "CNPJ_DO_SOLICITANTE*",
    "NOME_EMPRESARIAL",
    "NOME_COMUM",
    "MUNICIPIO_DO_SOLICITANTE",
    "UF_DO_SOLICITANTE",
    "TELEFONE_PRINCIPAL*",
    "SITUACAO_DO_PEDIDO",
    "ACESSO_AO_PEDIDO",
    "VIDEO",
    "NOME_DO_AGR_APROVADOR*",
    "CPF_AGR_APROVADOR*",
    "DATA_DA_APROVACAO*",
    "SALA_DE_APROVACAO",
    "NOME_DO_AGR_VALIDADOR",
    "CPF_AGR_VALIDADOR",
    "DATA_DA_VALIDACAO",
    "SALA_DE_VALIDACAO*",
    "SITUACAO_DO_CERTIFICADO*",
    "NUMERO_DE_SERIE",
    "DATA_DA_INSTALACAO*",
    "DATA_DA_EXPIRACAO*",
    "DATA_DA_REVOGACAO*",
  ],
  safeweb: [
    "PROTOCOLO*",
    "NOME*",
    "DOCUMENTO*",
    "NOME_DO_TITULAR*",
    "CPF_DO_TITULAR*",
    "DATA_NASCIMENTO_TITULAR*",
    "EMAIL_TITULAR*",
    "TELEFONE_TITULAR*",
    "PRODUTO*",
    "VALIDADE*",
    "NOME_AVP*",
    "CPF_AVP*",
    "DATA_AVP*",
    "DATA_INICIO_VALIDADE*",
    "DATA_FIM_VALIDADE*",
    "STATUS*",
    "DATA_REVOGACAO*",
    "REVOGADO_POR*",
    "LOCAL_ATENDIMENTO*",
  ],
} as any;
