import { BrowserRouter as Router, Switch } from 'react-router-dom';
import MomentUtils from '@date-io/moment';
import { ptBR } from '@material-ui/data-grid';
import { ToastContainer } from 'react-toastify';
import { ConfirmProvider } from 'material-ui-confirm';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { createTheme, ThemeProvider } from '@material-ui/core';

import './assets/css/tachyons.css';
import './assets/css/global_style.css';
import 'react-toastify/dist/ReactToastify.css';

import Login from './pages/Login';
import Emissoes from './pages/Emissoes';
import Usuarios from './pages/Usuarios';
import { Upload } from './pages/Upload';
import { Home } from './pages/Home/Home';
import Config from './pages/Config/Config';
import { AuthProvider } from './hooks/auth';
import Route from './components/CustomRoute';
import History from './pages/History/History';
import ChangePassword from './pages/ChangePassword';
import { Empresas } from './pages/Empresas/Empresas';
import { ProfileOptions } from './utils/ProfileOptions';
import MenuWithTopBar from './components/MenuWithTopbar';
import { PublicEmissionStatus } from './pages/PublicEmissionStatus/PublicEmissionStatus';

function App() {
  const theme = createTheme(
    {
      palette: {
        primary: {
          main: '#3f51b5',
        },
      },
    },
    ptBR
  );

  return (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <AuthProvider>
          <ConfirmProvider
            defaultOptions={{
              title: 'Você tem certeza?',
              cancellationText: 'Cancelar',
            }}
          >
            <Router>
              <Switch>
                <Route path="/" exact component={Login} isGuest />
                <Route
                  isGuest
                  path="/change-password"
                  component={ChangePassword}
                />
                <Route
                  isGuest
                  path="/emission-status"
                  component={PublicEmissionStatus}
                />

                <Route
                  isPrivate
                  path="/home"
                  component={Home}
                  layout={MenuWithTopBar}
                />
                <Route
                  isPrivate
                  path="/emissions"
                  component={Emissoes}
                  layout={MenuWithTopBar}
                  allowedProfiles={[
                    ProfileOptions.ADMIN,
                    ProfileOptions.GERENTE,
                    ProfileOptions.PADRAO,
                  ]}
                />
                <Route
                  isPrivate
                  path="/user"
                  component={Usuarios}
                  layout={MenuWithTopBar}
                  allowedProfiles={[
                    ProfileOptions.ADMIN,
                    ProfileOptions.GERENTE,
                  ]}
                />
                <Route
                  isPrivate
                  path="/upload"
                  component={Upload}
                  layout={MenuWithTopBar}
                  allowedProfiles={[ProfileOptions.ADMIN]}
                />
                <Route
                  path="/history"
                  component={History}
                  layout={MenuWithTopBar}
                  isPrivate
                  allowedProfiles={[
                    ProfileOptions.ADMIN,
                    ProfileOptions.GERENTE,
                  ]}
                />
                <Route
                  isPrivate
                  path="/business"
                  component={Empresas}
                  layout={MenuWithTopBar}
                  allowedProfiles={[
                    ProfileOptions.ADMIN,
                    ProfileOptions.VENDAS,
                    ProfileOptions.SUPERVISOR,
                  ]}
                />
                <Route
                  path="/config"
                  component={Config}
                  layout={MenuWithTopBar}
                  isPrivate
                />
              </Switch>
            </Router>
          </ConfirmProvider>
        </AuthProvider>
      </MuiPickersUtilsProvider>

      <ToastContainer />
    </ThemeProvider>
  );
}

export default App;
