import FilterEmission from "../interfaces/FilterEmission";
import validateDocument from "./validateDocument";

const validateEmissionFilter = ({
    protocolo,
    // nome,
    documento,
    titularCpf,
    // avpNome,
    dt,
    dtInicio,
    dtFim,
    avpCpf,
    produto,
    localAtendimento,
    status,
    situacao
}: FilterEmission): boolean => {
    if(protocolo && protocolo.toString().length < 8) {
        throw new Error('O campo [Protocolo] necessita de pelo menos 8 dígitos para ser filtrado!');
    }

    // if(nome && nome.length < 3) {
    //     throw new Error('O campo [Razão Social/Titular Nome] necessita de pelo menos 4 letras para ser filtrado!');
    // }

    // if(cpfCnpj && !validateDocument(cpfCnpj)) {
    //     throw new Error('O Documento informado não é válido!');
    // }

    // if(avpNome && avpNome.length < 3) {
    //     throw new Error('O campo [Nome do AVP] necessita de pelo menos 4 letras para ser filtrado!');
    // }

    if(protocolo && protocolo.toString().length > 10) {
        throw new Error('O campo [Protocolo] não pode ter mais de 10 dígitos!');
    }
    
    if(documento) {
        const doc = documento.replace(/[^0-9]/g, "");
        if(doc.length === 0) {
            return false;
        }
        else if(!validateDocument(doc)) {
            throw new Error('O Documento informado não é válido!');
        }
    }

    if(titularCpf) {
        const tCpf = titularCpf.replace(/[^0-9]/g, "");
        if(tCpf.length === 0) {
            return false;
        }
        else if(!validateDocument(tCpf)) {
            throw new Error('O CPF, do campo [Titular CPF], não é válido!');
        }
    }

    if(produto && produto !== "PJ" && produto !== "PF") {
        throw new Error('O campo [Produto] não possui um valor válido!');
    }

    if(localAtendimento && localAtendimento.length < 3) {
        throw new Error('O campo [Local de Atendimento] necessita de pelo menos 4 letras para ser filtrado!');
    }

    if(avpCpf) {
        const aCpf = avpCpf.replace(/[^0-9]/g, "");
        if(aCpf.length === 0) {
            return false;
        }
        else if(!validateDocument(aCpf)) {
            throw new Error('O campo [AVP CPF] não possui um valor válido!');
        }
    }

    if(status && !['E', 'R', 'P', 'C', "EP", "RC"].includes(status)) {
        throw new Error('O campo [Status] não possui um valor válido!');
    }

    if(situacao && situacao !== 'V' && situacao !== 'A') {
        throw new Error('O campo [Situação] não possui um valor válido!');
    }

    if(dt) { 
        if(!['dtInicioValidade', 'dtFimValidade', 'dtAvp', 'dtRevogacao'].includes(dt)) {
            throw new Error('O campo [Data] não possui um valor válido!');
        }

        if(!dtInicio && !dtFim) {
            throw new Error('Informe o campo [Início Período] ou [Fim Período] para realizar filtragem por Data!');
        }

        if(dtInicio && dtFim && dtInicio.isAfter(dtFim)) {
            throw new Error('O campo [Início Período] não pode ser maior que o campo [Fim Período]!');
        }
    }

    if(dtInicio) {
        if(!dtInicio.isValid()) {
            return false;
        }
    }

    if(dtFim) {
        if(!dtFim.isValid()) {
            return false;
        }
    }

    return true;
}

export default validateEmissionFilter;