import { useCallback, useEffect, useMemo, useState } from "react";
import { Card, TablePagination, Typography } from "@material-ui/core";

import addToast from "../../utils/toast";
import { useAuth } from "../../hooks/auth";
import Filter from "../../components/Filter";
import Button from "../../components/Button";
import { tableConfig } from "./Empresas.utils";
import Empresa from "../../resources/empresas";
import { API } from "../../resources/api.types";
import CustomTable from "../../components/CustomTable";
import { ProfileOptions } from "../../utils/ProfileOptions";
import { FilterBusiness } from "../../interfaces/FilterBusiness";
import { EmpresasFilter } from "./components/EmpresasFilter/EmpresasFilter";
import { CreateEmpresasDialog } from "./components/CreateEmpresasDialog/CreateEmpresasDialog";

export const Empresas: React.FC = () => {
  const [data, setData] = useState([] as API.Empresa[]);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [filter, setFilter] = useState<FilterBusiness>({} as FilterBusiness);
  const {
    authData: { user },
  } = useAuth();

  const userIsAdminOrSupervisor = [
    ProfileOptions.ADMIN,
    ProfileOptions.SUPERVISOR,
  ].includes(user.perfil);

  const getPhoneNumber = async (id: number) => {
    Empresa.copiarTelefoneDaEmpresa(id)
      .then((res) => {
        if (res.data?.phoneNumber) {
          navigator.clipboard.writeText(res.data?.phoneNumber).then(
            function () {
              addToast(
                "Número copiado para área de transferência (Ctrl + c).",
                { type: "info" }
              );
            },
            function (err) {
              addToast(
                `Erro ao copiar telefone! (Contacte o administrador - ${err})`,
                { type: "error" }
              );
            }
          );
        }
      })
      .catch((e) => console.log({ e }));
  };

  const tableActions = useCallback((row: API.Empresa) => {
    const options = [];

    if (row?.status === "ATIVA") {
      options.push({
        label: "Copiar telefone",
        onClick: () => getPhoneNumber(row.id),
      });
    }

    return options;
  }, []);

  const getEmpresas = useCallback(
    (userFilter: FilterBusiness, page: number, perPage: number) => {
      try {
        Empresa.getAll(userFilter, page + 1, perPage)
          .then((response) => {
            const { data } = response;

            setTotal(data.total);
            setData(data.empresas);
          })
          .catch((error) => {
            if (error?.data?.message) {
              addToast(error.data.message, { type: "error" });
            } else {
              console.error({ filterBusinessError: error });
            }
          });
      } catch (error: any) {
        addToast(error.message, { type: "error" });
      }
    },
    []
  );

  const canCleanFilters = useMemo(
    () =>
      Object.keys(filter).some((key) =>
        Boolean(filter[key as keyof FilterBusiness])
      ),
    [filter]
  );

  const handleClearButtonClick = () => {
    setData([]);
    setFilter({} as FilterBusiness);
  };

  const handlePerPageChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handlePageChange = (_: any, newPage: number) => setPage(newPage);

  useEffect(() => {
    getEmpresas(filter, page, perPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, perPage]);

  return (
    <div className="pa8 pa24-l">
      <Card className="pa16">
        <div className="flex justify-between mb8">
          <div
            style={{ height: 36 }}
            className="flex justify-center items-center"
          >
            <h2>Empresas</h2>
          </div>
          <div className="flex">
            {canCleanFilters && (
              <Button
                buttonValue="Limpar Filtros"
                onClick={handleClearButtonClick}
                variant="text"
              />
            )}
            {userIsAdminOrSupervisor && (
              <Button
                buttonValue="Cadastrar"
                onClick={() => setDialogOpen(true)}
              />
            )}
          </div>
        </div>
        <Filter
          filter={filter}
          setFilter={setFilter}
          FilterComponent={EmpresasFilter}
          onFilter={() => {
            setPage(0);
            getEmpresas(filter, 0, perPage);
          }}
        />
      </Card>

      <Card className="pa16 mt24">
        <Typography variant="h6">Registros Encontrados: {total}</Typography>
        <br />

        <CustomTable
          data={data}
          actions={tableActions as any}
          {...tableConfig}
        />

        <div className="w-100 flex justify-end">
          <TablePagination
            page={page}
            colSpan={13}
            count={total}
            rowsPerPage={perPage}
            style={{ border: "none" }}
            onPageChange={handlePageChange}
            labelRowsPerPage="Linhas por página"
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            onRowsPerPageChange={handlePerPageChange}
            SelectProps={{
              inputProps: { "aria-label": "rows per page" },
              native: true,
            }}
          />
        </div>
      </Card>

      {isDialogOpen && userIsAdminOrSupervisor && (
        <CreateEmpresasDialog
          setDialogOpen={setDialogOpen}
          executeAfterFinish={() => {
            setPage(0);
            getEmpresas(filter, 0, perPage);
          }}
        />
      )}
    </div>
  );
};
